import React from 'react';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';

import { useSelector, useDispatch } from "react-redux";
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import { gapi } from "gapi-script";

gapi.load("client:auth2", () => {
  gapi.client.init({
    clientId:
      "*****.apps.googleusercontent.com",
    plugin_name: "chat",
  });
});

const GoogleAuth = () => {
    const dispatch = useDispatch();

    const handleGoogleLogin = async (response) => {
        const accessToken = response.accessToken;

        try {
          const res = await axios.post('/api/login/google/', {
            access_token: accessToken,
          });
          if (res.status === 'success'){
            sessionStorage.setItem("authUser", JSON.stringify(res));
            window.location.href = '/dashboard';
        }
          
        } catch (error) {
            // Handle any errors
            console.error(error);
        }
    };

  return (
    <>
    <GoogleLogin
      clientId="959233530005-gdcumpmfmshennqv5t66n3nbm42blf6q.apps.googleusercontent.com"
      buttonText="Sign in via Google"
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleLogin}
      cookiePolicy={'single_host_origin'}
      render={(renderProps) => (
        <button 
            type="button"
          className="btn btn-danger btn-icon waves-effect waves-light w-100"
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
        >
          <i className="ri-google-fill fs-16"></i>&nbsp; Sign in via Google
        </button>
      )}
    /></>
  );
};

export default GoogleAuth;
