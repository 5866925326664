import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import Register from "../pages/Authentication/Register";
import Chat from "../pages/Chat";
import Faxes from "../pages/Faxes";
import FileManager from "../pages/FileManager";
import FaxPages from "../pages/Faxes/FaxPages";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";
import EmailVerification from "../pages/Authentication/EmailVerification";
import PaymentHandler from "../pages/Authentication/PaymentHandler";

const authProtectedRoutes = [
  // { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/chat", component: <Chat /> },
  { path : "/files", component: <FileManager/> },
  //User Profile
  { path: "/settings", component: <UserProfile /> },
  { path: "/fax", component: <Faxes /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/fax/:fax_id", component: <FaxPages /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/chat" />,
  },
  { path: "*", component: <Navigate to="/chat" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/reset-password", component: <ResetPasswordPage /> },
  { path: "/register", component: <Register /> },
  { path: "/verify-email", component: <EmailVerification/>,},
  { path: "/payment/feedback", component: <PaymentHandler/>},
  { path: "/payment/cancel", component: <PaymentHandler/>},
];

export { authProtectedRoutes, publicRoutes };
