
import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const FaxPageText = ({ page }) => {
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => setShowModal(!showModal);

	return (
		<>
			<button
				className="btn btn-link"
				style={{
					border: "1px solid #000",
					textDecoration: "none",
				}}
				onClick={toggleModal}
			>
				Show Text
			</button>

			<Modal isOpen={showModal} toggle={toggleModal} size="lg">
				<ModalHeader toggle={toggleModal}>Fax Page</ModalHeader>
				<ModalBody>
					<p style={{ whiteSpace: "pre-wrap" }}>{page.fax_text}</p>
				</ModalBody>
				<ModalFooter>
					<Button color="secondary" onClick={toggleModal}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default FaxPageText;

