import React, { useState } from 'react';
import { APIClient } from "../../helpers/api_helper";

const FaxDelete = ({ fax_id, getFaxes }) => {
    const api = new APIClient();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const onFaxDelete = async () => {
        try {
            const shouldDelete = window.confirm('Are you sure you want to delete this fax?');

            if (shouldDelete) {
                const response = await api.put(`/api/fax/${fax_id}/`);
                if (response.status === "success") {
                    getFaxes();
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setShowConfirmDialog(false);
        }
    };

    return (
        <>
            <div>
                <i className="bx bx-trash cursor-pointer" onClick={onFaxDelete}></i>
            </div>
        </>
    );
};

export default FaxDelete;
