import React, { useEffect, useState } from "react";
import moment from "moment";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { Alert } from "reactstrap";
import { Progress } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { Switch, FormControlLabel } from "@material-ui/core";

import {
	Manager,
	Card,
	CardBody,
	Col,
	Reference,
	Container,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Popover,
	FormGroup,
	Modal,
	ModalBody,
	Input,
	Button,
	ModalHeader,
	Row,
	UncontrolledDropdown,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import axios from "axios";

const FileManager = () => {
	const [fileList, setFileList] = useState([]);
	const [isUploadModalOpen, setUploadModalOpen] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [activeTab, setActiveTab] = useState("file");
	const [actionMsg, setActionMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [fontSize, setFontSize] = useState("normal");

	const [fileFormatErrorMsg, setFileFormatErrorMsg] = useState("");

	const allowedFileFormats = ".doc,.pdf,.txt,.docx,.png,.jpeg,.jpg";

	const [isDragging, setIsDragging] = useState(false);

	const [title, setTitle] = useState("");
	const [text, setText] = useState("");

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0); // Total number of pages
	const pageSize = 8; // Number of documents per page

	const [deleteBulk, showDeleteBulk] = useState(false);

	//Archived State : 
	const [loading, setLoading] = useState(false)

	const handleDragEnter = (e) => {
		e.preventDefault();
		setIsDragging(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setIsDragging(false);
	};

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setIsDragging(false);
		handleFileUpload(e.dataTransfer.files);
	};

	const toggleSelectAll = () => {
		setSelectAll(!selectAll);
		setSelectedFiles(selectAll ? [] : fileList.map((item) => item.id));
		showDeleteBulk(!selectAll);
	};

	const toggleSelectFile = (fileId) => {
		setSelectedFiles((prevSelectedFiles) => {
			if (prevSelectedFiles.includes(fileId)) {
				return prevSelectedFiles.filter((id) => id !== fileId);
			} else {
				return [...prevSelectedFiles, fileId];
			}
		});
	};

	useEffect(() => {
		// Check if any checkbox is checked
		const isChecked = selectedFiles.length > 0;
		showDeleteBulk(isChecked);
	}, [selectedFiles]);

	const api = new APIClient();

	// Upload file handler
	const handleFileUpload = async (files) => {
		try {
			const allowedFileTypes = ["doc", "pdf", "txt", "xls", "xlsx", "docx", "jpg", "jpeg", "png"];
			const invalidFiles = [];
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				const fileExtension = file.name.split(".").pop().toLowerCase();

				if (!allowedFileTypes.includes(fileExtension)) {
					invalidFiles.push(file);
				}
			}

			if (invalidFiles.length > 0) {
				const invalidFileNames = invalidFiles.map((file) => file.name);
				setFileFormatErrorMsg(`Invalid Files : ${invalidFileNames.join("\n")}`);
				return;
			}

			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append("files", files[i]);
			}
			setShowFileUploadLoader(true);

			const response = await api.create("/api/document/", formData, {
				onUploadProgress: (progressEvent) => {
					const progress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					console.log(progress);
					setUploadProgress(progress);
				},
			});
			console.log("File upload response:", response);

			if (response.status == 'success') {
				setActionMsg(response.message);
			}
			else {
				setErrorMsg(response.message);
			}

			setTimeout(() => {
				setErrorMsg("");
				setActionMsg("");
			}, 5000); // Set the timeout to 3000 milliseconds (3 seconds)

			// Reset upload progress
			setTimeout(setUploadProgress(0), 30000);

			fetchFileList();

			// You can choose whether to close the upload modal here or keep it open
			// closeUploadModal();
		} catch (error) {
			console.error("Error uploading files:", error);
		}
		closeUploadModal();
		setShowFileUploadLoader(false);
	};

	const fetchFileList = async () => {
		try {
			// Perform API call to fetch file list
			const response = await api.get("/api/document/", {
				params: {
					page: currentPage,
					pageSize: pageSize, // Remove this line if not used
				},
			});
			setFileList(response);
			setTotalPages(Math.ceil(response.length / pageSize));
		} catch (error) {
			console.error("Error fetching file list:", error);
		}
	};

	const loadingTextStyle = {
		animation: "slideIn 1s linear infinite",
		whiteSpace: "nowrap",
	};

	const getPaginatedFiles = () => {
		const startIndex = (currentPage - 1) * pageSize;
		const endIndex = startIndex + pageSize;
		return fileList.slice(startIndex, endIndex);
	};

	// Load file list on component mount
	useEffect(() => {
		fetchFileList();
	}, [currentPage]);

	// Open upload modal
	const openUploadModal = () => {
		setUploadModalOpen(true);
	};

	const handleInitializeChat = async () => {
		setshowChatInitLoader(true);
		try {
			const chatInit = await initializeChat();
		} catch (error) {
			console.error("Error initializing chat:", error);
		}
	};

	// Close upload modal
	const closeUploadModal = () => {
		setUploadModalOpen(false);
		setFileFormatErrorMsg("");
	};

	// Toggle active tab
	const toggleTab = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const formatFileSize = (sizeInBytes) => {
		if (sizeInBytes < 1024) {
			return sizeInBytes + " B";
		} else if (sizeInBytes < 1024 * 1024) {
			const sizeInKB = (sizeInBytes / 1024).toFixed(2);
			return sizeInKB + " KB";
		} else if (sizeInBytes < 1024 * 1024 * 1024) {
			const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
			return sizeInMB + " MB";
		} else {
			const sizeInGB = (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2);
			return sizeInGB + " GB";
		}
	};

	const handleSubmitTrainbyText = async () => {
		// Create an object with input values
		const inputData = {
			title: title,
			text: text,
		};

		api
			.create("/api/train/", inputData)
			.then(async (response) => {
				console.log(response.data);
				fetchFileList();
				const chatInit = await initializeChat();
			})
			.catch((error) => {
				console.error("Error:", error);
			});
		closeUploadModal();
	};

	const handleDownload = (documentId, name) => {
		api
			.get(`/api/document/${documentId}/download/`, {
				responseType: "blob",
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", name); // Set the desired file name
				document.body.appendChild(link);
				link.click();
				link.remove();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const [showChatInitLoader, setshowChatInitLoader] = useState(false);
	const [showFileUploadLoader, setShowFileUploadLoader] = useState(false);
	const [fileUploadMsg, setFileUploadMsg] = useState("Uploading...");

	function initializeChat() {
		// setLoading(true);
		// setShowLoader(true);
		// setshowChatInitLoader(true);

		// api
		// 	.get("/api/chat-initialize/")
		// 	.then((response) => {
		// 		setTimeout(() => setshowChatInitLoader(false), 30000);
		// 		console.log(response.data);
		// 	})
		// 	.catch((error) => {
		// 		console.error(error);
		// 		setshowChatInitLoader(false);
		// 	})
		// 	.finally(() => {
		// 		setshowChatInitLoader(false);
		// 		// setLoading(false);
		// 		// setTimeout(() => setShowLoader(false), 3000);
		// 	});
	}

	const handleDelete = async (documentId) => {
		confirmAlert({
			title: "Confirm Deletion",
			message: "Are you sure you want to delete this file?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						api
							.delete(`/api/document/${documentId}/`)
							.then(async () => {
								const chatInit = await initializeChat();
								fetchFileList();
								setActionMsg("File Removed Successfully.");
								setTimeout(() => {
									setActionMsg("");
								}, 3000); // Set the timeout to 3000 milliseconds (3 seconds)
							})
							.catch((error) => {
								console.error(error);
							});
					},
				},
				{
					label: "No",
					onClick: () => {
						// Do nothing if the user clicks "No" or closes the dialog
					},
				},
			],
		});
	};

	const handleArchive = async (document_id) => {
		setLoading(true);
		api
			.put(`api/document/${document_id}/archive/`).then((res) => {
				fetchFileList();
				setActionMsg(res.message)
				setLoading(false)
				setTimeout(() => {
					setActionMsg("");
				}, 3000);
			}).catch((err) => {
				console.log(err);
			})

	};




	const handleResync = (document_id) => {
		setLoading(true);
		api
			.get(`api/document/${document_id}/sync/`).then((res) => {
				fetchFileList();
				setActionMsg(res.message);
				setLoading(false);
				setTimeout(() => {
					setActionMsg("");
				}, 3000); // Set the timeout to 3000 milliseconds (3 seconds)
			}).catch((error) => {
				console.error(error);
			});
	}

	const handleAllResync = () => {
		setLoading(true);
		api
			.get(`api/document/sync/`).then((res) => {
				fetchFileList();
				setActionMsg(res.message);
				setLoading(false);
				setTimeout(() => {
					setActionMsg("");
				}, 3000); // Set the timeout to 3000 milliseconds (3 seconds)
			}).catch((err) => {
				console.log(err)
			})
	}


	const deleteBulkFiles = async () => {
		confirmAlert({
			title: "Confirm Deletion",
			message: "Are you sure you want to delete this file?",
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						try {
							await Promise.all(
								selectedFiles.map(async (documentId) => {
									await api.delete(`/api/document/${documentId}/`);
								})
							);
							setActionMsg("Files Removed Successfully.");
							fetchFileList();
							setSelectAll(false);
							setSelectedFiles([]);
							setTimeout(async () => {
								setActionMsg("");
								// Call your await function here
								const chatInit = await initializeChat();
							}, 3000);
						} catch (error) {
							console.error(error);
						}
					},
				},
				{
					label: "No",
					onClick: () => { },
				},
			],
		});
	};

	const trainAIBotManual = (document) => {
		setshowChatInitLoader(true);
		document.is_active = true;
		api.get(`/api/train/${document.id}/`)
			.then(async (response) => {
				setActionMsg(response.message);
				setshowChatInitLoader(false);
			})
			.catch((error) => {
				console.error(error);
				setshowChatInitLoader(false);
			});
	}

	const handleFileActionChange = async (file) => {
		const documentId = file.id;
		const isActive = file.is_active;
		file.is_active = !file.is_active;
		setActionMsg("");
		try {
			// Make the API call with the new file action
			const response = await api.create('/api/document/action/', {
				documentId,
				isActive: isActive,
			}, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
			setActionMsg(response.message);
			file.doc_hash = response.doc_id;
		} catch (error) {
			setActionMsg(error);
		}
	};

	const popupStyle = {
		position: "fixed",
		top: "20px",
		right: "20px",
		color: "#ffff",
		background: "#28a745",
		padding: "20px",
		borderRadius: "5px",
		boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.3)",
		transition: "transform 0.3s ease-in-out",
		transform: actionMsg ? "translateY(0)" : "translateY(-100px)",
		opacity: actionMsg ? 1 : 0,
		zIndex: 9999,
	};

	const shakingStyle = {
		color: "#dc3545",
		animation: "shake 0.5s infinite",
		transform: "translateX(0)",
	};

	const uploadIconStyles = {
		fontSize: "4.5rem",
		padding: "4rem",
		color: "#575252",
	};

	const elmLoaderStyle = {
		background: "#000000",
		zIndex: 1000,
		opacity: 0.5,
		display: "flex",
		flexDirection: "column",
	};

	const archiveLoaderStyle = {
		
		background: "#000000",
		zIndex: 1000,
		opacity: 0.4,
		display: "flex",
		flexDirection: "column",
	};
	

	const elmLoaderRingCss = css`
	display: block;
	margin: 0 auto;
	border-color: red;
	`;

	return (
		<React.Fragment>
			<div className="page-content">
				{showChatInitLoader && (
					<div id="elmLoader" style={elmLoaderStyle}>
						<PuffLoader
							color="#ffffff"
							css={elmLoaderRingCss}
							size={75}
						/>
						<br />
						<div style={loadingTextStyle}>Training...</div>
					</div>
				)}
				<Container fluid>
					<div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
						<div className="file-manager-content w-100 p-3 py-0">
							<div className="mx-n3 pt-4 px-4 file-manager-content-scroll">
								<div>
									<div className="d-flex align-items-center mb-3">
										<h5 className="flex-grow-1 fs-16 mb-0" id="filetype-title">
											Content Manager
										</h5>
										{deleteBulk && (
											<div className="flex-shrink-0">
												<button
													className="btn btn-danger mx-2"
													onClick={deleteBulkFiles}
												>
													<i className="ri-delete-bin-line align-bottom"></i>{" "}
												</button>
											</div>
										)}
										<div className="flex-shrink-0">
											<button
												className="btn btn-primary mx-2"
												onClick={openUploadModal}
											>
												<i className="ri-upload-2-line align-bottom"></i> Upload
												Content
											</button>
										</div>
										<div className="flex-shrink-0">
											<button
												className="btn btn-primary mx-2"
												onClick={handleAllResync}
											>
												<i className="ri-refresh-line align-bottom"></i> Re-sync All
											</button>
										</div>
									</div>
									<div
										className="table-responsive"
										style={{ height: "calc(100vh - 350px)" }}
									>
										<table className="table align-middle table-nowrap mb-0">
											<thead className="table-active">
												<tr>
													<th scope="col">
														<div className="form-check">
															{getPaginatedFiles().length > 0 && (
																<input
																	className="form-check-input"
																	type="checkbox"
																	checked={selectAll}
																	onChange={toggleSelectAll}
																/>
															)}
														</div>
													</th>
													<th scope="col">Name</th>
													<th scope="col">Training Mode</th>
													<th scope="col">Uploaded At</th>
													<th scope="col" className="text-end">
														Size
													</th>
													<th scope="col" className="text-center">
														Active ?
													</th>
													{/* <th scope="col" className="text-center">
														Train
													</th> */}
													{/* <th scope="col" className="text-center">
														Actions
													</th> */}
													<th scope="col" className="text-center">
														Re-sync
													</th>
												</tr>
											</thead>

											{loading && <div id="elmLoader" style={archiveLoaderStyle}>
											
												<PuffLoader
													color="#ffffff"
													css={elmLoaderRingCss}

													size={75}
												/>
												<br />

											</div>} 



											<tbody id="file-list">
												{getPaginatedFiles().length === 0 ? (
													<tr>
														<td colSpan="8" className="text-center">
															<h2>
																<i className="ri-folder-open-line" />{" "}
															</h2>
															<h6>No content available</h6>
														</td>
													</tr>
												) : (
													(getPaginatedFiles() || []).map((item, key) => (
														<tr key={key}>
																<td>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="checkbox"
																			checked={selectedFiles.includes(item.id)}
																			onChange={() => toggleSelectFile(item.id)}
																		/>
																	</div>
																</td>
																<td>
																	<input
																		className="form-control filelist-id"
																		type="hidden"
																		value="1"
																		id="filelist-1"
																	/>
																	<div className="d-flex align-items-center">
																		<div className="flex-shrink-0 fs-17 me-2 filelist-icon">
																			<i className="ri-file-text-fill" />
																		</div>
																		{item.is_archive && <span
																			className="d-flex align-items-center mx-2 lex-grow-1 filelist-name"
																		>
																			(Archieved)
																		</span>}


																		<div className="flex-grow-1 filelist-name">
																			{item.name}
																		</div>

																		<div className="d-none filelist-type">
																			{item.doc_type}
																		</div>
																	</div>
																</td>
																<td className="filelist-mode">{item.doc_type}</td>
																<td className="filelist-time">
																	{moment(item.uploaded_at).format(
																		"MM-DD-YY - HH:mm"
																	)}
																</td>
																<td className="filelist-size text-end">
																	{formatFileSize(item.file_size)}
																</td>
																{/* <td className="filelist-size text-center">
																	<div>
																		<Switch
																			checked={item.is_active}
																			onChange={(event) => handleFileActionChange(item)}
																			color="primary"
																		/>
																	</div>
																</td> */}

																<td>
																	<div className="d-flex gap-3 justify-content-center">
																		<UncontrolledDropdown dir="start">
																			<DropdownToggle
																				tag="button"
																				className="btn btn-light btn-icon btn-sm dropdown"
																				id="dropdownMenuButton"
																			>
																				<i className="ri-more-fill align-bottom" />
																			</DropdownToggle>
																			<DropdownMenu className="dropdown-menu-end">
																				<DropdownItem
																					className="viewfile-list"
																					onClick={() =>
																						handleDownload(item.id, item.name)
																					}
																				>
																					Download
																				</DropdownItem>
																				<DropdownItem
																					className="remove-list"
																					onClick={() => handleDelete(item.id)}
																				>
																					Delete
																				</DropdownItem>
																				<DropdownItem
																					className="remove-list"
																					onClick={() => handleArchive(item.id)}
																				>
																					{item.is_archive ? "UnArchive" : "Archive"}
																				</DropdownItem>

																			</DropdownMenu>
																		</UncontrolledDropdown>
																	</div>
																</td>

																<td>
																	<div className="d-flex text-center justify-content-center">
																		<button
																			className="btn btn-primary mx-2"
																			onClick={() => handleResync(item.id)}
																		>
																			<i className="ri-refresh-line align-bottom"></i> Re-sync

																		</button>
																	</div>
																</td>
															</tr>
													))
												)}
											</tbody>





										</table>
									</div>


									{/* <div id="elmLoader" style={elmLoaderStyle}>
										<PuffLoader
											color="#ffffff"
											css={elmLoaderRingCss}
											size={75}
										/>
										<br />
										
									</div> */}

									<div className="align-items-center mt-2 row g-3 text-center text-sm-start">
										<div className="col-sm"></div>
										<div className="col-sm-auto">
											<div className="pagination">
												{totalPages > 0 && (
													<Pagination>
														<PaginationItem disabled={currentPage === 1}>
															<PaginationLink
																previous
																onClick={() =>
																	handlePageChange(currentPage - 1)
																}
															/>
														</PaginationItem>

														{Array.from({ length: totalPages }, (_, index) => (
															<PaginationItem
																key={index}
																active={currentPage === index + 1}
															>
																<PaginationLink
																	onClick={() => handlePageChange(index + 1)}
																>
																	{index + 1}
																</PaginationLink>
															</PaginationItem>
														))}

														<PaginationItem
															disabled={currentPage === totalPages}
														>
															<PaginationLink
																next
																onClick={() =>
																	handlePageChange(currentPage + 1)
																}
															/>
														</PaginationItem>
													</Pagination>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>
			{/* Upload Modal */}
			<Modal isOpen={isUploadModalOpen} toggle={closeUploadModal} size="lg">
				<ModalHeader toggle={closeUploadModal}>Upload Content</ModalHeader>
				<ModalBody>
					<Nav tabs>
						<NavItem>
							<NavLink
								className={activeTab === "file" ? "active" : ""}
								onClick={() => toggleTab("file")}
							>
								Files
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={activeTab === "text" ? "active" : ""}
								onClick={() => toggleTab("text")}
							>
								Text
							</NavLink>
						</NavItem>
					</Nav>

					<TabContent activeTab={activeTab}>
						<TabPane tabId="file">
							{/* Drag and drop area */}
							<div
								className={`upload-area ${isDragging ? "dragging" : ""}`}
								style={{
									padding: "50px 20px",
									textAlign: "center",
									border: "2px dashed",
									marginTop: "20px",
									height: "26rem",
								}}
								onDragEnter={handleDragEnter}
								onDragLeave={handleDragLeave}
								onDragOver={handleDragOver}
								onDrop={handleDrop}

							// onClick={() => document.getElementById("fileInput").click()}
							>
								{showFileUploadLoader && (
									<div id="elmLoader" style={elmLoaderStyle}>
										<PuffLoader
											color="#ffffff"
											css={elmLoaderRingCss}
											size={75}
										/>
										<br />
										<div style={loadingTextStyle}>{fileUploadMsg}</div>
									</div>
								)}

								{/* Drag and drop message */}
								<div className="upload-message">
									<h5>Drag and drop your files here</h5>
									<small>
										Accepted File Formats - (.pdf, .doc, .docx, .txt, .png, .jpeg, .jpg)
									</small>
									{fileFormatErrorMsg && (
										<div style={shakingStyle}>{fileFormatErrorMsg}</div>
									)}
									<p>or</p>
									<button
										className="btn btn-primary"
										onClick={(e) => {
											e.stopPropagation();
											document.getElementById("fileInput").click();
										}}
									>
										Select File(s)
									</button>
								</div>
								{/* Add input file element */}
								<input
									type="file"
									accept={allowedFileFormats}
									id="fileInput"
									style={{ display: "none" }}
									onChange={(e) => handleFileUpload(e.target.files)}
									multiple
								/>
								<div>
									<i className="bx bx-upload" style={uploadIconStyles}></i>
								</div>
							</div>
						</TabPane>

						<TabPane tabId="text">
							{/* Text input and textarea */}
							<div className="upload-area py-3">
								<FormGroup>
									<Input
										type="text"
										className="p-3"
										id="textInput"
										placeholder="Title"
										value={title}
										onChange={(e) => setTitle(e.target.value)}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										type="textarea"
										className="p-3"
										id="textArea"
										rows="10"
										placeholder="Add Text Here to Train the AI..."
										value={text}
										onChange={(e) => setText(e.target.value)}
									/>
								</FormGroup>
								<Button color="primary" onClick={handleSubmitTrainbyText}>
									Submit
								</Button>
							</div>
						</TabPane>
					</TabContent>
				</ModalBody>
			</Modal>

			{actionMsg && (
				<div className="alert alert-info flying-popup">
					<span>{actionMsg}</span>
				</div>
			)}

			{errorMsg && (
				<div className="alert alert-danger flying-popup flying-popup-error">
					<span>{errorMsg}</span>
				</div>
			)}
		</React.Fragment>
	);
};

export default FileManager;
