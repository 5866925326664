import React, { useState, useEffect } from "react";
import { Container, Table } from "reactstrap";

const BillingInfo = ({ profileDataObj }) => {
	const [currentPlan, setCurrentPlan] = useState(null);
	const [subscriptionId, setSubscriptionId] = useState(null);
	const [currentPlanEndDate, setCurrentPlanEndDate] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const getSubscriptionDetails = async () => {
			try {
				if (
					profileDataObj &&
					profileDataObj.subscription 
				) {
					const currentPlanData = profileDataObj.subscription.plan || null;
					const currentPlanEndDate = profileDataObj.subscription.end_date || null;
					setSubscriptionId(profileDataObj.subscription.stripeSubscriptionId);
					setCurrentPlan(currentPlanData);
					setCurrentPlanEndDate(currentPlanEndDate);
				} else {
					setCurrentPlan(null);
					setCurrentPlanEndDate(null);
				}
			} catch (error) {
				console.error("Error fetching subscription details:", error);
			} finally {
				setLoading(false);
			}
		};
		getSubscriptionDetails();
	}, [profileDataObj]);

	return (
		<div>
			<div className="py-3">
                <h1>Subscription</h1>
            </div>
			{loading ? (
				<p>Loading subscription details...</p>
			) : (
				<Table striped bordered hover>
					<tbody>
						{currentPlan ? (
							<React.Fragment>
								<tr>
									<td>Subscription ID</td>
									<td>{subscriptionId ? subscriptionId : "No paid subscription created yet."}</td>
								</tr>
								<tr>
									<td>Current Plan</td>
									<td>{currentPlan.name} </td>
									{/* ({currentPlan.is_default_plan ? "Free" : currentPlan.product_id})  */}
								</tr>
								<tr>
									<td>Maximum File Size</td>
									<td>{currentPlan.max_file_size} MB</td>
								</tr>
								<tr>
									<td>Maximum Files Uplaod</td>
									<td>{currentPlan.max_file_uploads}</td>
								</tr>
								<tr>
									<td>Maximum Questions Asked</td>
									<td>{currentPlan.max_questions}</td>
								</tr>
							</React.Fragment>
						) : null}
					</tbody>
				</Table>
			)}
		</div>
	);
};

export default BillingInfo;
