
import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import { Discuss, Comment, Bars, Circles } from "react-loader-spinner";
import { confirmAlert } from "react-confirm-alert";

import {
	Container,
	Button,
	UncontrolledTooltip,
	Input,
	DropdownToggle,
	DropdownMenu,
	Dropdown,
	DropdownItem,
	Row,
	Col,
	Card,
	CardBody,
	UncontrolledDropdown,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	ButtonGroup,
	TabPane,
} from "reactstrap";


import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";

import SimpleBar from "simplebar-react";
import { APIClient } from "../../helpers/api_helper";

import FeatherIcon from "feather-icons-react";
import PersonalInfo from "./PersonalInfo";

//redux
import { useDispatch } from "react-redux";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const Chat = () => {
	const [customActiveTab, setcustomActiveTab] = useState("1");

	const api = new APIClient();

	const dispatch = useDispatch();
	const [isInfoDetails, setIsInfoDetails] = useState(false);
	const [CurrentChannelID, setCurrentChannelID] = useState(null);
	const [curMessage, setcurMessage] = useState("");

	const [ChannelName, setChannelName] = useState("shikhar");
	const [search_Menu, setsearch_Menu] = useState(false);
	const [settings_Menu, setsettings_Menu] = useState(false);
	const [messageBox, setMessageBox] = useState(null);
	const [reply, setreply] = useState("");
	const [emojiPicker, setemojiPicker] = useState(false);
	const [currentUser, setCurrentUser] = useState({
		name: "",
		isActive: true,
	});

	// Local dummy data
	const [chats, setChats] = useState([]);
	const [messages, setMessages] = useState([]);
	const [channels, setChannels] = useState([]);
	const [showChatOpen, setShowChatOpen] = useState(false);

	// Chat Loader
	const [showChatLoader, setshowChatLoader] = useState(false);
	const [showChatTypingLoader, setshowChatTypingLoader] = useState(false);
	const [showChatInitLoader, setshowChatInitLoader] = useState(false);

	// New Channel
	const [newChannelInputValue, setNewChannelInputValue] = useState("");
	const [isNewChannelInputVisible, setIsNewChannelInputVisible] =
		useState(false);

	//Toggle Chat Box Menus
	const toggleSearch = () => {
		setsearch_Menu(!search_Menu);
	};

	//Info details offcanvas
	const toggleInfo = () => {
		setIsInfoDetails(!isInfoDetails);
	};

	const toggleSettings = () => {
		setsettings_Menu(!settings_Menu);
		// Add some comments
	};

	const [menus, setMenus] = useState([]);

	useEffect(() => {
		// Fetch chats, channels, and messages here
		const fetchChatData = async () => {
			try {
				// Fetch channels
				const channelsResponse = await fetchChannels();
				setChannels(channelsResponse);

				if (!CurrentChannelID && channelsResponse.length > 0) {
					setCurrentChannelID(channelsResponse[0].id);
					setChannelName(channelsResponse[0].name);
				}
				// Fetch messages for current room ID

				if (CurrentChannelID) {
					const messagesResponse = await fetchMessages(CurrentChannelID);
					setMessages(messagesResponse);
				}
			} catch (error) {
				console.error("Error fetching chat data:", error);
			}
		};
		fetchChatData();
	}, [CurrentChannelID]);

	// Fetch channels (dummy implementation)
	const fetchChannels = () => {
		return api
			.get("/api/channel/")
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error("Error fetching channels:", error);
				return [];
			});
	};

	// Fetch messages for a specific room ID (dummy implementation)
	const fetchMessages = (channel_id) => {
		return api
			.get(`/api/chat/?channel_id=${channel_id}`)
			.then((response) => {
				return response;
			})
			.catch((error) => {
				console.error("Error fetching channels:", error);
				return [];
			});
	};

	const [getMessagesLoader, setGetMessagesLoader] = useState(false);

	const getMessages = async (channel_id) => {
		setGetMessagesLoader(true);
		try {
			const data = await fetchMessages(channel_id);
			console.log(channel_id);
			setGetMessagesLoader(false);

			return data;
		} catch (error) {
			console.error("Error fetching messages:", error);
			setGetMessagesLoader(false);
			return [];
		}
	};

	//Use For Chat Box
	const userChatOpen = (name, channel_id) => {
		setChannelName(name);
		setCurrentChannelID(channel_id);
		setIsEditingChannelName(false);
		getMessages(channel_id).then((response) => {
			setMessages(response);
			setShowChatOpen(true);
		});
	};

	const addSeperator = (width = 60) => {
		return `\n${"-".repeat(width)}\n`;
	  };
	  
	const format_question_answers = (context) => {
		let formatted_question_answers = "";
		// formatted_question_answers += addSeperator();
		// formatted_question_answers += "Additional Information:\n";
		formatted_question_answers += "\n";
		formatted_question_answers += context
			.map((ctx) => `${ctx.question} : ${ctx.answer}`)
			.join("\n");
		return formatted_question_answers;
	};

	window.stopGenerating = false;

	const handleStopGeneratingClick = () => {
		window.stopGenerating = true;
	};

	const addMessage = async (channel_id, sender, message, menu_id="", isPopupSubmit=false, context=[]) => {

		if ((!message)){
			return;
		}

		if ((showChatLoader) || (showChatTypingLoader)){
			return;
		}
		let displayMessage = message;

		if (menu_id !== '' && isPopupSubmit !== false && context.length !== 0) {
			const formatted_question_answer = format_question_answers(context);
			displayMessage += formatted_question_answer
		}

		setIsListening(false);
		setshowChatLoader(true);
		setshowChatTypingLoader(true);

		const newMessage = {
			channel_id,
			sender,
			message: displayMessage,
			createdAt: new Date(),
		};

		newMessage['id'] = messages.length + 1;

		setMessages([...messages, newMessage]);
		setcurMessage("");

		let responseGenerated = false;
		let chatStreamCallUrl = `/api/chat-stream/?message=${encodeURIComponent(message)}&channel_id=${channel_id}`;

		if ((menu_id !== '')) {
			chatStreamCallUrl += `&menu_id=${menu_id}&isPopupSubmit=${isPopupSubmit}&context=${JSON.stringify(context)}`;
		}

		const chatStreamCall = api.stream(chatStreamCallUrl);
		const response = await chatStreamCall;
		const streamReader = response.body.getReader();

		let decoder = new TextDecoder();
		let finalAnswer = '';

		while (true) {
			const { done, value } = await streamReader.read();
			setshowChatTypingLoader(false);

			if ( done ){
				break;
			}
			
			if (window.stopGenerating == true){
				streamReader.cancel();
				window.stopGenerating = false;
			}

			const decodedData = decoder.decode(value);
			const newStreamData = decodedData;
			
			finalAnswer = finalAnswer + newStreamData;
			
			if (!responseGenerated) {
				const initialMessage = {
					message: newStreamData,
					createdAt: new Date(),
					user_type: 'gpt',
					channel_id: channel_id
				};		  
			  	setMessages((prevMessages) => [...prevMessages, initialMessage]); // Append the initial message
		  		responseGenerated = true;
			}
			else {
			  	setMessages((prevMessages) => {
					const updatedMessages = [...prevMessages];
					const lastMessageIndex = updatedMessages.length - 1;
					updatedMessages[lastMessageIndex].message += newStreamData; // Append new stream data to the last received message
					return updatedMessages;
				});
			}
		}
		if (finalAnswer){
			// Register Chat to Server
			await api.create("/api/chat/", {
				message: finalAnswer,
				channel_id: CurrentChannelID,
			});
		}
		setshowChatLoader(false);
		return finalAnswer;
	};

	const scrollToBottom = useCallback(() => {
		if (messageBox) {
			messageBox.scrollTop = messageBox.scrollHeight + 1000000;
		}
	}, [messageBox]);

	useEffect(() => {
		if (!isEmpty(messages)) scrollToBottom();
	}, [messages, scrollToBottom]);

	//serach recent user
	const searchUsers = () => {
		var input, filter, li, a, i, txtValue;
		input = document.getElementById("search-user");
		filter = input.value.toUpperCase();
		var userList = document.getElementsByClassName("users-list");
		Array.prototype.forEach.call(userList, function (el) {
			li = el.getElementsByTagName("li");
			for (i = 0; i < li.length; i++) {
				a = li[i].getElementsByTagName("a")[0];
				txtValue = a.textContent || a.innerText;
				if (txtValue.toUpperCase().indexOf(filter) > -1) {
					li[i].style.display = "";
				} else {
					li[i].style.display = "none";
				}
			}
		});
	};

	//Search Message
	const searchMessages = () => {
		var searchInput, searchFilter, searchUL, searchLI, a, txtValue;
		searchInput = document.getElementById("searchMessage");
		searchFilter = searchInput.value.toUpperCase();
		searchUL = document.getElementById("users-conversation");
		searchLI = searchUL.getElementsByTagName("li");
		Array.prototype.forEach.call(searchLI, function (search) {
			a = search.getElementsByTagName("p")[0]
				? search.getElementsByTagName("p")[0]
				: "";
			txtValue =
				a.textContent || a.innerText ? a.textContent || a.innerText : "";
			if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
				search.style.display = "";
			} else {
				search.style.display = "none";
			}
		});
	};

	const onUserChatRemove = () => {
		setShowChatOpen(false);
	};

	const handleChatSubmit = (e) => {
		e.preventDefault();
	};

	const [channelExistsError, setChannelExistsError] = useState(false);
	const [channelNoNameError, setChannelNoNameError] = useState(false);

	const handleSaveNewChannel = async () => {
		// Perform the API call using the newChannelInputValue
		// Add any additional logic you require
		setChannelNoNameError(false);

		const inputValue = newChannelInputValue;

		if (!inputValue) {
			setChannelNoNameError(true);
			return;
		}

		const channelExists = channels.some(
			(channel) => channel.name === inputValue
		);
		if (channelExists) {
			// Show an error or handle the existing channel case
			setChannelExistsError(true);
			return; // Abort further execution
		}

		const response = await api.create("/api/channel/", {
			name: inputValue,
		});

		setChannels([...channels, response]);
		setCurrentChannelID(response.id);
		setChannelName(response.name);

		// Clear the input value and hide the new channel input
		setNewChannelInputValue("");
		setIsNewChannelInputVisible(false);
	};

	const shakingStyle = {
		color: "#dc3545",
		animation: "shake 0.5s infinite",
		transform: "translateX(0)",
	};

	const elmLoaderStyle = {
		background: "#000000",
		zIndex: 1000,
		opacity: 0.5,
		display: "flex",
		flexDirection: "column",
	};

	const elmLoaderRingCss = css`
	display: block;
	margin: 0 auto;
	border-color: red;
	`;

	const [speechMessage, setSpeechMessage] = useState(null);

	const handleListenButtonClick = (audioText, message_id) => {
		if (audioText) {
			if (speechMessage && speechMessage.text === audioText) {
				// If the same audio is already playing, stop it
				if (window.speechSynthesis && window.speechSynthesis.speaking) {
					window.speechSynthesis.cancel();
				}
				setSpeechMessage(null);
			} else {
				// Start or resume the new audio
				const message = new SpeechSynthesisUtterance(audioText);
				message.lang = "en-US";
				message.volume = 1;
				message.rate = 1;
				message.pitch = 1;
				message.id = message_id;
				message.onend = () => {
					setSpeechMessage(null);
				};
				// If there was a previous speech message, cancel it before starting a new one
				if (
					speechMessage &&
					window.speechSynthesis &&
					window.speechSynthesis.speaking
				) {
					window.speechSynthesis.cancel();
				}

				// Start or resume speaking
				if (window.speechSynthesis && window.speechSynthesis.speak) {
					window.speechSynthesis.speak(message);
				}
				setSpeechMessage(message);
			}
		}
	};

	const loadingTextStyle = {
		animation: "slideIn 1s linear infinite",
		whiteSpace: "nowrap",
	};

	const [isEditingChannelName, setIsEditingChannelName] = useState(false);

	const handleEditChannelName = () => {
		setIsEditingChannelName(true);
	};

	const handleSaveEditChannelName = () => {
		return api
			.put(`/api/channel/${CurrentChannelID}/`, { name: ChannelName })
			.then((response) => {
				setChannelName(response.name);
				const updatedChannels = channels.map((item) => {
					if (item.id === response.id) {
						return { ...item, name: response.name };
					}
					return item;
				});
				setChannels(updatedChannels);
				setIsEditingChannelName(false);
			})
			.catch((error) => {
				setIsEditingChannelName(false);
				console.error("Error fetching channels:", error);
			});
	};

	const [hoveredChannelId, sethoveredChannelId] = useState(null);

	const handleChannelHover = (hoveredChannelId) => {
		sethoveredChannelId(hoveredChannelId);
		console.log("Delete channel:", hoveredChannelId);
	};

	const handleChannelHoverLeave = (hoveredChannelId) => {
		sethoveredChannelId(null);
		console.log("Delete channel:", hoveredChannelId);
	};

	const handleChannelDelete = () => {
		confirmAlert({
		  title: "Delete Channel",
		  message: "Are you sure you want to delete this channel?",
		  buttons: [
			{
			  label: "Yes",
			  onClick: () => {
				return api
				  .delete(`/api/channel/${hoveredChannelId}/`, {})
				  .then((response) => {
					const updatedChannels = channels.filter(
					  (item) => item.id !== hoveredChannelId
					);
					setChannels(updatedChannels);
					if (updatedChannels.length > 0) {
					  userChatOpen(updatedChannels[0].name, updatedChannels[0].id);
					}
				  })
				  .catch((error) => {
					console.error("Error deleting channel:", error);
				  });
			  },
			},
			{
			  label: "No",
			  onClick: () => {
			  },
			},
		  ],
		});
	};

	const [isListening, setIsListening] = useState(false);
	const [transcript, setTranscript] = useState("");

	let recognition = null;

	const startListening = () => {
		recognition = new window.webkitSpeechRecognition();
		recognition.continuous = true;
		recognition.interimResults = true;

		recognition.onresult = (event) => {
			let transcript = "";

			for (let i = event.resultIndex; i < event.results.length; ++i) {
				transcript += event.results[i][0].transcript + " ";
			}

			setTranscript((prevTranscript) => prevTranscript + transcript);
			setcurMessage((prevMessage) => transcript);
		};

		recognition.start();
		setIsListening(true);
	};

	const stopListening = () => {
		if (recognition) {
			recognition.stop();
		}
		setIsListening(false);
	};

	const toggleListening = () => {
		if (isListening) {
			stopListening();
		} else {
			startListening();
		}
	};


	const [ isCopying, setIsCopying ] = useState(false);
	const [ copyingMessageId, setCopyingMessageId ] = useState(null);

	const handleCopyButtonClick = (message, message_id) => {
		setIsCopying(true);
		setCopyingMessageId(message_id);
		navigator.clipboard.writeText(message)
		.then(() => {
			setTimeout(function(){
				setIsCopying(false);
			}, 3000)
		}).catch((error) => {

			setTimeout(function(){
				setIsCopying(false);
			}, 3000)
		});
	};

	const handleTextareaKeyDown = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault(); // Prevent the default newline behavior
			submitButton.click(); // Programmatically trigger the button click
		}
	};
	
	let submitButton; // Reference to the submit button

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
						{showChatInitLoader && (
							<div id="elmLoader" style={elmLoaderStyle}>
								<PuffLoader color="#ffffff" css={elmLoaderRingCss} size={75} />
								<br />
								<div style={loadingTextStyle}>Gathering Resources...</div>
							</div>
						)}
						<div className="chat-leftsidebar">
							<div className="px-4 pt-4 mb-4">
								<div className="d-flex align-items-start">
									<div className="flex-grow-1">
										<h5 className="mb-4">Chats</h5>
									</div>
								</div>
								<div className="search-box">
									<input
										onKeyUp={searchUsers}
										id="search-user"
										type="text"
										className="form-control bg-light border-light"
										placeholder="Search here..."
									/>
									<i className="ri-search-2-line search-icon"></i>
								</div>
							</div>
							<TabContent activeTab={customActiveTab} className="text-muted">
								<TabPane tabId="1" id="chats">
									<SimpleBar
										className="chat-room-list pt-3"
										style={{ margin: "-16px 0px 0px" }}
									>
										<div className="d-flex align-items-center px-4 pt-2 mb-2">
											<div className="flex-grow-1">
												<h4 className="mb-0 fs-11 text-muted text-uppercase">
													Channels
												</h4>
											</div>
											<div className="flex-shrink-0">
												<UncontrolledTooltip
													placement="bottom"
													target="createnewChannel"
												>
													Create New Channel
												</UncontrolledTooltip>
												<Button
													color=""
													id="createnewChannel"
													className="btn btn-soft-success btn-sm"
													onClick={() =>
														setIsNewChannelInputVisible(
															!isNewChannelInputVisible
														)
													}
												>
													<i className="ri-add-line align-bottom"></i>
												</Button>
											</div>
										</div>

										<div className="chat-message-list">
											{isNewChannelInputVisible && (
												<div className="px-4 pt-2 mb-2">
													{channelExistsError && (
														<div style={shakingStyle}>
															Channel already exists!
														</div>
													)}

													{channelNoNameError && (
														<div style={shakingStyle}>
															Enter a channel name...
														</div>
													)}
													<div className="d-flex align-items-center">
														<input
															type="text"
															className="form-control"
															placeholder="Enter new channel name..."
															value={newChannelInputValue}
															onChange={(e) =>
																setNewChannelInputValue(e.target.value)
															}
															style={{
																border: channelExistsError
																	? "1px solid red"
																	: "1px solid #ccc",
															}}
														/>
														<button
															className="btn btn-primary btn-sm ms-2"
															onClick={handleSaveNewChannel}
														>
															<i className="ri-check-line align-bottom"></i>
														</button>
													</div>
												</div>
											)}

											<ul
												className="list-unstyled chat-list chat-user-list mb-0 users-list"
												id="channelList"
											>
												{channels.length === 0 ? (
													<p className="px-3 pt-2">
														No channels available. <br />
														Click (+) to create channel & start conversation.
													</p>
												) : (
													channels.map((channel, key) => (
														<React.Fragment key={key}>
															<li
																className={
																	channel.id === CurrentChannelID
																		? "active"
																		: ""
																}
															>
																<Link
																	href="#"
																	className="unread-msg-user"
																	onClick={(e) => {
																		e.preventDefault();
																		userChatOpen(channel.name, channel.id);
																	}}
																>
																	<div
																		className="d-flex align-items-center"
																		onMouseEnter={() =>
																			handleChannelHover(channel.id)
																		}
																		onMouseLeave={() =>
																			handleChannelHoverLeave(channel.id)
																		}
																	>
																		<div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
																			<div className="avatar-xxs">
																				<div className="avatar-title bg-light rounded-circle text-body">
																					#
																				</div>
																			</div>
																		</div>

																		<div className="flex-grow-1 overflow-hidden">
																			<p className="text-truncate mb-0">
																				{channel.name}
																			</p>
																		</div>
																		{hoveredChannelId === channel.id && (
																			<div className="flex-shrink-0">
																				<span className="rounded p-1 text-muted">
																					<FeatherIcon
																						icon="trash"
																						className="icon-sm"
																						onClick={handleChannelDelete}
																					/>
																				</span>
																			</div>
																		)}
																	</div>
																</Link>
															</li>
														</React.Fragment>
													))
												)}
											</ul>
										</div>
									</SimpleBar>
								</TabPane>
							</TabContent>
						</div>

						<div
							className={`user-chat w-100 overflow-hidden ${
								showChatOpen ? "user-chat-show" : ""
							}`}
						>
							<div className="chat-content d-lg-flex">
								<div className="w-100 overflow-hidden position-relative">
									{channels.length === 0 ? (
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												minHeight: "100%",
											}}
										></div>
									) : (
										<div className="position-relative">
											<div className="p-3 user-chat-topbar">
												<Row className="align-items-center">
													<Col sm={4} xs={8}>
														<div className="d-flex align-items-center">
															<div className="flex-shrink-0 d-block d-lg-none me-3">
																<Link
																	to="#"
																	className="user-chat-remove fs-18 p-1"
																	onClick={onUserChatRemove}
																>
																	<i className="ri-arrow-left-s-line align-bottom"></i>
																</Link>
															</div>
															<div className="flex-grow-1 overflow-hidden">
																<div className="d-flex align-items-center">
																	<div className="flex-grow-1 overflow-hidden">
																		{isEditingChannelName ? (
																			<Input
																				onChange={(e) =>
																					setChannelName(e.target.value)
																				}
																				type="text"
																				className="form-control bg-light border-light w-100"
																				value={ChannelName} // Use the state variable for the input value
																			/>
																		) : (
																			// Code block when isEditingChannelName is false
																			<h5 className="text-truncate mb-0 fs-16">
																				# {ChannelName}
																			</h5>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</Col>
													<Col sm={8} xs={4}>
														<ul className="list-inline user-chat-nav text-end mb-0">
															<li className="list-inline-item mr-3">
																{isEditingChannelName ? (
																	<button
																		className="btn btn-ghost-secondary btn-icon"
																		onClick={() =>
																			handleSaveEditChannelName(true)
																		}
																	>
																		<FeatherIcon
																			icon="save"
																			className="icon-sm"
																		/>
																	</button>
																) : (
																	<button
																		className="btn btn-ghost-secondary btn-icon"
																		onClick={() => handleEditChannelName(true)}
																	>
																		<FeatherIcon
																			icon="edit"
																			className="icon-sm"
																		/>
																	</button>
																)}
															</li>

															<li className="list-inline-item m-0">
																<Dropdown
																	isOpen={search_Menu}
																	toggle={toggleSearch}
																>
																	<DropdownToggle
																		className="btn btn-ghost-secondary btn-icon"
																		tag="button"
																	>
																		<FeatherIcon
																			icon="search"
																			className="icon-sm"
																		/>
																	</DropdownToggle>
																	<DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-lg">
																		<div className="p-2">
																			<div className="search-box">
																				<Input
																					onKeyUp={searchMessages}
																					type="text"
																					className="form-control bg-light border-light"
																					placeholder="Search here..."
																					id="searchMessage"
																				/>
																				<i className="ri-search-2-line search-icon"></i>
																			</div>
																		</div>
																	</DropdownMenu>
																</Dropdown>
															</li>
														</ul>
													</Col>
												</Row>
											</div>

											<div className="position-relative" id="users-chat">
												<PerfectScrollbar
													className="chat-conversation p-3 p-lg-4"
													id="chat-conversation"
													containerRef={(ref) => setMessageBox(ref)}
												>
													{getMessagesLoader && (
														<div id="elmLoader" style={elmLoaderStyle}>
															<PuffLoader
																color="#ffffff"
																css={elmLoaderRingCss}
																size={75}
															/>
															<br />
															<div style={loadingTextStyle}>
																Fetching Messages...
															</div>
														</div>
													)}
													<ul
														className="list-unstyled chat-conversation-list"
														id="users-conversation"
													>
														{messages &&
															map(messages, (message, key) => (
																<li
																	className={
																		message.user_type === "gpt"
																			? " chat-list left"
																			: "chat-list right"
																	}
																	key={key}
																>
																	<div className="conversation-list">
																		<div className="user-chat-content">
																			<div className="ctext-wrap">
																				<div className="ctext-wrap-content" style={{ textAlign: "left" }}>
																					<span
																						className="mb-0 ctext-content"
																						style={{ whiteSpace: "pre-wrap" }}
																					>
																						{message.message}
																					</span>
																					{message.isMenu && (
																						<div className="py-2">
																							{menus &&
																								menus.map((menu, menu_key) => (
																									<div key={menu_key}>
																										<button
																											className="btn btn-light text-center my-2 py-2"
																											onClick={() =>
																												handleMenuClick(menu)
																											}
																										>
																											{menu.name}
																										</button>
																									</div>
																								))}
																						</div>
																					)}
																					<div>
																						<button
																						title="Play this message"
																						key={message.id}
																						onClick={() => handleListenButtonClick(message.message, message.id)}
																						style={{
																							// position: "absolute",
																							bottom: 0,
																							right: 0,
																							border: "none",
																							background: "none",
																							padding: 0,
																							float: "right",
																							marginTop: 10,
																							display: "flex",
																							alignItems: "center",
																							color: "var(--primary)",
																						}}
																						>
																							<i
																								className={`bx ${
																								speechMessage && speechMessage.id === message.id
																									? "bx-pause-circle"
																									: "bx-play-circle"
																								}`}
																							></i> 
																						</button>
																					</div>
																					<div>
																						<button
																							title="Copy this message"
																							key={message.id}
																							onClick={() => handleCopyButtonClick(message.message, message.id)}
																							style={{
																								// position: "absolute",
																								bottom: 0,
																								right: 0,
																								border: "none",
																								background: "none",
																								padding: 0,
																								float: "right",
																								marginTop: 10,
																								display: "flex",
																								alignItems: "center",
																								color: "var(--primary)",
																							}}
																							>
																							<i
																								className={`px-2 bx ${
																									isCopying && copyingMessageId === message.id
																										? "bx-check"
																										: "bx-copy"
																									}`}
																							></i>
																						</button>
																					</div>
																				</div>
																			</div>
																			<div className="conversation-name">
																				<small className="text-muted time">
																					{moment(message.createdAt).format(
																						"MM-DD-YY - HH:mm"
																					)}
																				</small>{" "}
																				<span className="text-success check-message-icon">
																					<i className="ri-check-double-line align-bottom"></i>
																				</span>
																			</div>
																		</div>
																	</div>
																</li>
															))}

														{showChatTypingLoader && (
															<li className="chat-list left">
																<div className="conversation-list">
																	<div className="user-chat-content">
																		<div className="ctext-wrap">
																			<div className="ctext-wrap-content">
																				<Comment
																					visible={true}
																					height="50"
																					width="50"
																					ariaLabel="comment-loading"
																					wrapperStyle={{}}
																					wrapperClass="comment-wrapper"
																					backgroundColor="#22c798"
																				/>
																			</div>
																		</div>
																	</div>
																</div>
															</li>
														)}
													</ul>
												</PerfectScrollbar>
											</div>
											<div className="chat-input-section p-3 p-lg-4">
												<form id="chatinput-form" onSubmit={handleChatSubmit}>
													<Row className="g-0 align-items-center">
														<div className="col">
															<div className="chat-input-feedback">
																Please Enter a Message
															</div>
															<textarea
																type="text"
																value={curMessage}
																// onKeyPress={onKeyPress}
																onKeyDown={handleTextareaKeyDown} // Handle the keydown event
																onChange={(e) => setcurMessage(e.target.value)}
																className="form-control chat-input bg-light border-light"
																id="chat-input"
																placeholder="Ask me anything...(Press shift+enter for next line)"
															/>
														</div>
														<div className="col-auto">
															<div className="chat-input-links ms-2">
																{showChatLoader ? (
																	<ButtonGroup>
																		<Button onClick={handleStopGeneratingClick} style={{ display:"flex" }}  className="btn btn-danger px-2">
																			<small className="px-2" style={{ fontSize: 13 }}>
																				<i className="bx bx-stop"></i> 
																				Stop Generating
																			</small>
																		</Button>
																	</ButtonGroup>) : (<div className="links-list-item">
																	<ButtonGroup>
																		<Button onClick={toggleListening}>
																			{isListening ? (
																				<Bars
																					visible={true}
																					height="15"
																					width="15"
																					color="#ffff"
																					ariaLabel="bars-loading"
																					wrapperStyle={{}}
																					wrapperClass=""
																				/>
																			) : (
																				<i className="bx bx-microphone"></i>
																			)}
																		</Button>
																		<Button
																			type="submit"
																			color="success"
																			innerRef={(ref) => (submitButton = ref)} // Assign the ref to the submitButton variable
																			onClick={() => {
																				addMessage(
																					CurrentChannelID,
																					currentUser.name,
																					curMessage,
																				);
																			}}
																			className="chat-send waves-effect waves-light"
																		>
																			<i className="ri-send-plane-2-fill align-bottom"></i>
																		</Button>
																	</ButtonGroup>
																</div>)}
															</div>
														</div>
													</Row>
												</form>
											</div>

											<div className={reply ? "replyCard show" : "replyCard"}>
												<Card className="mb-0">
													<CardBody className="py-3">
														<div className="replymessage-block mb-0 d-flex align-items-start">
															<div className="flex-grow-1">
																<h5 className="conversation-name">
																	{reply && reply.sender}
																</h5>
																<p className="mb-0">{reply && reply.message}</p>
															</div>
															<div className="flex-shrink-0">
																<button
																	type="button"
																	id="close_toggle"
																	className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
																	onClick={() => setreply("")}
																>
																	<i className="bx bx-x align-middle"></i>
																</button>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>

			<PersonalInfo
				show={isInfoDetails}
				onCloseClick={() => setIsInfoDetails(false)}
				currentuser={ChannelName}
			/>
		</React.Fragment>
	);
};

export default Chat;