import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import withRouter from '../../Components/Common/withRouter';
import { APIClient } from '../../helpers/api_helper';
const EmailVerification = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const api = new APIClient();

  // Get the value of a specific parameter
  const uid = queryParams.get('uid');
  const token = queryParams.get('token');

  const [ mailVerifyMsg, setMailVerifyMsg ] = useState('');

  useEffect(() => {
    // Perform the API call
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/verify-email/?uid=${uid}&token=${token}`);
        setMailVerifyMsg(response.message);
        console.log(response.data); // Handle the API response data
      } catch (error) {
        console.error(error); // Handle error case
      }
    };
    fetchData();
    }, []); // Empty dependency array ensures the effect runs only once


  // Render the component with the parameter values
  return (
    <React.Fragment>
        <ParticlesAuth>
            <div className="auth-page-content">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="100" />
                                    </Link>
                                </div>
                                <p className="mt-3 fs-15 fw-medium">AI Based Document Response Generator</p>
                            </div>
                        </Col>
                    </Row>
                    { mailVerifyMsg && (<Row>
                        <Col lg={12}>
                            <div className='alert text-center alert-info'>
                                <span>
                                    
                                    { mailVerifyMsg }
                                
                                </span>   
                            </div>
                        </Col>

                        <div className="mt-4 text-center">
                            <p className="mb-0">Back to <Link to="/login" className="fw-semibold text-white text-primary text-decoration-underline"> Login </Link> </p>
                        </div> 
                    </Row>)}

                    
                </Container>
            </div>
        </ParticlesAuth>
    </React.Fragment>
  )
};

export default EmailVerification;
