import BillingInfo from "./BillingInfo";
import PlansInfo from "./PlansInfo";
import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink, Container, Row, Col, Card, Alert, CardBody, Button, Label, Input, FormFeedback, Form, InputGroup, InputGroupText } from "reactstrap";
import { Switch, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Faxes from "./Faxes";
import { editProfile, resetProfileFlag } from "../../store/actions";
import { APIClient } from "../../helpers/api_helper";
import { apiKey } from "../../common/data";
import avatar from "../../assets/images/users/avatar-1.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

const UserProfile = () => {
  const api = new APIClient();
  const dispatch = useDispatch();
  const [userFirstName, setUserFirstName] = useState("");
  const [profileDataObj, setProfileDataObj] = useState({});
  const [userLastName, setUserLastName] = useState("");
  const [email, setEmail] = useState("");
  const [apiFocus, setApiFocus] = useState("documents");
  const [profileFormSubmitError, setProfileFormSubmitError] = useState(null);
  const [profileFormSubmitSuccess, setProfileFormSubmitSuccess] = useState(null);
  const [activeTab, setActiveTab] = useState("profile");

  const handleApiFocusChange = (event) => {
    setApiFocus(event.target.checked ? "gpt" : "documents");
  };

  const updateUserData = (profileObj) => {
    if (profileObj) {
      setProfileDataObj(profileObj);
      setUserFirstName(profileObj.user.first_name);
      setUserLastName(profileObj.user.last_name);
      setEmail(profileObj.user.email);
      setApiFocus(profileObj.api_focus);
    }
  };

  const resetFormStatus = () => {
    setProfileFormSubmitError(null);
    setProfileFormSubmitSuccess(null);
  };

  const queryParams = new URLSearchParams(location.search);
  const showBilling = queryParams.get('showBilling');

  const getSetActiveTab = (showBilling) => {
    if (showBilling == "1") {
      setActiveTab("billing");
    }
  }

  useEffect(() => {
    const getInitProfileData = async () => {
      const profileObj = await getUserProfile();
      updateUserData(profileObj);
    };
    getInitProfileData();
    getSetActiveTab(showBilling);
  }, [showBilling]);

  const getUserProfile = () => {
    return api
      .get("/api/profile/")
      .then((response) => {
        const profileObj = response;
        return profileObj;
      })
      .catch((error) => {
        console.error("Error fetching Profile:", error);
        setProfileFormSubmitError("Error Updating Profile.");
        return [];
      });
  };

  const updateSessionUsername = (userName) => {
    const authUserData = JSON.parse(sessionStorage.getItem("authUser"));
    authUserData.data["username"] = userName;
    sessionStorage.setItem("authUser", JSON.stringify(authUserData));
  };

  const updateUserProfile = (values) => {
    return api
      .put("/api/profile/", values)
      .then((response) => {
        setProfileFormSubmitSuccess("Profile saved successfully.");
        updateUserData(response);
        updateSessionUsername(response.user_data.username);
        return response;
      })
      .catch((error) => {
        console.error("Error fetching Profile:", error);
        setProfileFormSubmitError("Failed to update profile.");
        return [];
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userFirstName || "",
      last_name: userLastName || "",
      email: email || "",
      api_focus: apiFocus || "documents",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      api_focus: Yup.string().required("Please Select the API Focus"),
    }),
    onSubmit: (values) => {
      resetFormStatus();
      delete values.email;
      updateUserProfile(values);
    },
  });

  document.title = "Doc Trainee";

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Nav tabs>
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                active={activeTab === "profile"}
                onClick={() => setActiveTab("profile")}
              >
                Preferences
              </NavLink>
            </NavItem>
            {profileDataObj && profileDataObj.is_subscription_visible && (
            <NavItem>
              <NavLink
                tag={Link}
                to="/BillingInfo"
                active={activeTab === "billing"}
                onClick={() => setActiveTab("billing")}
              >
                Subscription
              </NavLink>
            </NavItem>
            )}
            <NavItem>
              <NavLink
                tag={Link}
                to="/Faxes"
                active={activeTab === "faxes"}
                onClick={() => setActiveTab("faxes")}
              >
                Faxes
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            <CardBody>
              {activeTab === "profile" && (
                <Form
                  className="form-horizontal"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                >
                  {profileFormSubmitError && (
                    <div className="alert alert-danger flying-popup">
                      <span>{profileFormSubmitError}</span>
                    </div>
                  )}
                  {profileFormSubmitSuccess && (
                    <div className="alert alert-success flying-popup">
                      <span>{profileFormSubmitSuccess}</span>
                    </div>
                  )}
                  <Row>
                    <Col className="pt-3" md="6">
                      <div className="form-group">
                        <Label className="form-label">First Name</Label>
                        <Input
                          name="first_name"
                          className="form-control"
                          placeholder="Enter Your First Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.first_name || ""}
                          invalid={
                            validation.touched.first_name &&
                            validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.first_name &&
                          validation.errors.first_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.first_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="pt-3" md="6">
                      <div className="form-group">
                        <Label className="form-label">Last Name</Label>
                        <Input
                          name="last_name"
                          className="form-control"
                          placeholder="Enter Your Last Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.last_name || ""}
                          invalid={
                            validation.touched.last_name &&
                            validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        {validation.touched.last_name &&
                          validation.errors.last_name ? (
                          <FormFeedback type="invalid">
                            {validation.errors.last_name}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col className="pt-3" md="6">
                      <div className="form-group">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email &&
                            validation.errors.email
                              ? true
                              : false
                          }
                          readOnly
                        />
                        {validation.touched.email &&
                          validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="6" className="pt-3">
                      <div className="form-group">
                        <Label className="form-label">
                          Context Focus{" "}
                          <small className="text-muted">
                            ( Should We Focus on your Documents or Switch to GPT Mode ? )
                          </small>
                        </Label>
                        <div>
                          <span className="toggle-label">Documents</span>
                          <Switch
                            checked={apiFocus === "gpt"}
                            onClick={handleApiFocusChange}
                            color="primary"
                          />
                          <span className="toggle-label">GPT</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-end mt-4">
                    <Button type="submit" color="danger">
                      Save Profile
                    </Button>
                  </div>
                </Form>
              )}
              {activeTab === "billing" && (
					<>
						<BillingInfo profileDataObj={profileDataObj} />
						<PlansInfo profileDataObj={profileDataObj}/>
					</>
			  )}
              {activeTab === "faxes" && (
                <>
                  <Faxes
                    profileDataObj={profileDataObj}
                    profileFormSubmitError={profileFormSubmitError}
                    getUserProfile={getUserProfile}
                    updateUserProfile={updateUserProfile}
                    resetFormStatus={resetFormStatus}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
