import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const FaxPageImage = ({ page }) => {
  const [showModal, setShowModal] = useState(false);
  const imageDataUrl = `data:image/png;base64, ${page.fax_image_base64_data}`;

  const toggleModal = () => setShowModal(!showModal);

  return (
    <>
      <button
        className="btn btn-link"
        style={{
          border: "1px solid #000",
          textDecoration: "none",
        }}
        onClick={toggleModal}
      >
        Show Image
      </button>

      <Modal isOpen={showModal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Fax Page Image</ModalHeader>
        <ModalBody>
          <img src={imageDataUrl} alt="Fax Page" className="img-fluid" />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default FaxPageImage;
