import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { PuffLoader } from "react-spinners";
import {
	Row,
	Col,
	Button,
	CardBody,
	Spinner,
	Card,
	Alert,
	Container,
	Input,
	Label,
	Form,
	FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleAuth from "./GoogleAuth";
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";
import { registerUserSuccessful, registerUserFailed } from "../../store/auth/register/actions";

import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { APIClient } from "../../helpers/api_helper";
import { apiKey } from "../../common/data";

const Register = () => {

	const api = new APIClient();

	const history = useNavigate();
	const dispatch = useDispatch();

	const urlParams = new URLSearchParams(window.location.search);
	const paramPlan = urlParams.get("selectedPlan");

	const [plans, setPlans] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingMsg, setLoadingMsg ] = useState(null);

	const [paymentStatus, setPaymentStatus] = useState(null);
	const [paymentStatusMessage, setPaymentStatusMessage] = useState("");

	const [ checkoutSessionId, setCheckoutSessionId ] = useState(urlParams.get("session_id"));

	const storeFormDataLocal = (values) => {
		// Set Data in Localstorage to retrieve it further.
		localStorage.setItem("docReaderRegistrationData", JSON.stringify(values));
	}

	const removeFormDataLocal = (values) => {
		localStorage.removeItem('docReaderRegistrationData');
	}

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			first_name: "",
			plan: paramPlan || "",
			password: "",
			confirm_password: "",
			isDefaultPlan: false,
		},
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid email address")
				.required("Please enter your email"),
			first_name: Yup.string().required("Please enter your username"),
			plan: Yup.string().required("Please select a plan"),
			password: Yup.string()
				.min(6, "Password must be at least 6 characters")
				.required("Password is required"),
			confirm_password: Yup.string()
				.oneOf([Yup.ref("password"), null], "Passwords must match")
				.required("Confirm password is required"),
		}),

		onSubmit: (values) => {
			setLoading(true);
			setLoadingMsg(null);

			const existingPlans = plans;
			const freePlanObj = existingPlans.filter((plan) => plan.product_id === values.plan)[0];
			if (freePlanObj.is_default_plan === true){
				//Double Validation is at Server Side.
				dispatch(registerUser(values));
				setTimeout(() => setLoading(false), 5000);
			}

			else{
				setLoadingMsg("Redirecting to payment gateway...");
				storeFormDataLocal(values);
				api.create("/api/register-checkout/", values)
				.then((response) => {
					if (response.status === "success") {
						// Redirect to checkout Page.
						window.location.href = response.checkout_url;
					} else {
						dispatch(registerUserFailed(response.message));
					}
				})
				.catch((error) => {
					dispatch(registerUserFailed(error));
				})
				.finally(() => {
					setLoading(false);
					setLoadingMsg(null);
				});
				return;
			}
		},
	});

	const { error, registrationError, success } = useSelector((state) => ({
		registrationError: state.Account.registrationError,
		success: state.Account.success,
		error: state.Account.error,
	}));

	const fetchPlans = () => {
		return api
		.get("/api/plan/")
		.then((response) => {
			setPlans(response);
			return response;
		})
		.catch((error) => {
			console.error("Error fetching Plans:", error);
			return [];
		});
	};

	const validateSessionRegister = async (values) => {
		const sessionId = checkoutSessionId;
		
		setLoading(true);
		setLoadingMsg("Checking Payment Status...");
		
		setCheckoutSessionId(null);

		api.create(`/api/register-checkout/validate/${sessionId}/`, values)
			.then((response) => {
				// Reset Checkout SessionId
				if (response.status === "success"){
					dispatch(registerUserSuccessful(response));
				}
				else{
					dispatch(registerUserFailed(response.message));
				}
			})
			.catch((error) => {
				dispatch(registerUserFailed(error));
			})
			.finally(() => {
				setLoading(false);
				setLoadingMsg(null);
			});
		return;
	}

	const checkSessionAndRegister = async () => {
		
		if(checkoutSessionId){
			const savedFormData = JSON.parse(localStorage.getItem("docReaderRegistrationData"));
			if (savedFormData){
				validation.setValues(savedFormData);
				await validateSessionRegister(savedFormData);
			}
		}
		else{
			removeFormDataLocal();
		}
	}

	useEffect(() => {
		// Fetch plans and handle any potential errors
		fetchPlans();
		if (dispatch) {
		  	dispatch(apiError(""));
		}

		// Handle success and reset register flag
		if (success) {
		//   setTimeout(() => history("/login"), 8000);
		}

		setTimeout(() => {
		  	// dispatch(resetRegisterFlag());
		}, 8000);

		// Set document title
		document.title = "DocReader.ai";

		// Check Session Id.
		checkSessionAndRegister();
		
	}, [dispatch, 
		history,
		success,
		error, 
		checkoutSessionId,
	]);

	const loadingTextStyle = {
		animation: "slideIn 1s linear infinite",
		whiteSpace: "nowrap",
	};

	const elmLoaderStyle = {
		background: "#000000",
		zIndex: 1000,
		opacity: 0.5,
		display: "flex",
		flexDirection: "column",
	};

	const elmLoaderRingCss = css`
		display: block;
		margin: 0 auto;
		border-color: red;
		`;

	return (
		<React.Fragment>
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-5 mb-4 text-white-50">
									<div>
										<Link to="/" className="d-inline-block auth-logo">
											<img src={logoLight} alt="" height="100" />
										</Link>
									</div>
									<p className="mt-3 fs-15 fw-medium">
										AI Based Document Response Generator
									</p>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							{loadingMsg && (<div id="elmLoader" style={elmLoaderStyle}>
								<PuffLoader
									color="#ffffff"
									css={elmLoaderRingCss}
									size={75}
								/>
								<br />
								<div style={loadingTextStyle}>
									{loadingMsg}
								</div>
							</div>)}
							<Col md={8} lg={6} xl={5}>
								<Card className="mt-4">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<h5 className="text-primary text-white">
												Create New Account
											</h5>
											<p className="text-muted">
												Get your DocReader.ai account now
											</p>
										</div>
										<div className="p-2 mt-4">
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
												}}
												className="needs-validation"
												action="#"
											>
												{success && (
													<>
														{/* {toast("You will be redirected to Login Page...", {
															position: "top-right",
															hideProgressBar: false,
															className: "bg-success text-white",
															progress: undefined,
															toastId: "",
														})}
														<ToastContainer autoClose={5000} limit={1} /> */}
														<Alert color="success">
															Boom!! Registration Successful. Please check your
															email to verify your account.
														</Alert>
													</>
												)}

												{error && (
													<Alert color="danger">
														<div>{registrationError}</div>
													</Alert>
												)}

												<div className="mb-3">
													<Label htmlFor="useremail" className="form-label">
														Email <span className="text-danger">*</span>
													</Label>
													<Input
														id="email"
														name="email"
														className="form-control"
														placeholder="Enter email address"
														type="email"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.email || ""}
														invalid={
															validation.touched.email &&
															validation.errors.email
																? true
																: false
														}
													/>
													{validation.touched.email && validation.errors.email && (
														<FormFeedback type="invalid">
															<div>{validation.errors.email}</div>
														</FormFeedback>
													)}
												</div>
												<div className="mb-3">
													<Label htmlFor="username" className="form-label">
														Full Name <span className="text-danger">*</span>
													</Label>
													<Input
														name="first_name"
														type="text"
														placeholder="Enter your name" // Set document title
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.first_name || ""}
														invalid={
															validation.touched.first_name &&
															validation.errors.first_name
																? true
																: false
														}
													/>
													{validation.touched.first_name &&
														validation.errors.first_name && (
															<FormFeedback type="invalid">
																<div>{validation.errors.first_name}</div>
															</FormFeedback>
														)}
												</div>
												<div className="mb-3">
													<Label htmlFor="userpassword" className="form-label">
														Password <span className="text-danger">*</span>
													</Label>
													<Input
														name="password"
														type="password"
														placeholder="Enter Password"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.password || ""}
														invalid={
															validation.touched.password &&
															validation.errors.password
																? true
																: false
														}
													/>
													{validation.touched.password &&
														validation.errors.password && (
															<FormFeedback type="invalid">
																<div>{validation.errors.password}</div>
															</FormFeedback>
														)}
												</div>

												<div className="mb-3">
													<Label
														htmlFor="confirmPassword"
														className="form-label"
													>
														Confirm Password{" "}
														<span className="text-danger">*</span>
													</Label>
													<Input
														name="confirm_password"
														type="password"
														placeholder="Confirm Password"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.confirm_password || ""}
														invalid={
															validation.touched.confirm_password &&
															validation.errors.confirm_password
																? true
																: false
														}
													/>
													{validation.touched.confirm_password &&
														validation.errors.confirm_password && (
															<FormFeedback type="invalid">
																<div>{validation.errors.confirm_password}</div>
															</FormFeedback>
														)}
												</div>

												<div className="mb-2">
													<Label htmlFor="plan" className="form-label">
														Plan <span className="text-danger">*</span>
													</Label>
													<select
														name="plan"
														onChange={(e) =>
															validation.setFieldValue("plan", e.target.value)
														}
														onBlur={validation.handleBlur}
														value={validation.values.plan}
														className={`form-select ${
															validation.touched.plan && validation.errors.plan
																? "is-invalid"
																: ""
														}`}
													>
														<option value="" disabled>
															Select a plan
														</option>
														{plans.map((plan) => (
															<option
																key={plan.product_id}
																value={plan.product_id}
																data-is-default-plan={plan.is_default_plan}
															>
																{plan.name}
															</option>
														))}
													</select>
													{validation.touched.plan && validation.errors.plan && (
														<FormFeedback type="invalid">
															<div>{validation.errors.plan}</div>
														</FormFeedback>
													)}
												</div>

												<div className="mb-4">
													<p className="mb-0 fs-12 text-muted">
														By registering you agree to the DocReader.ai &nbsp;
														<Link
															to="#"
															className="text-primary text-decoration-underline fst-normal fw-medium text-white"
														>
															Terms of Use
														</Link>
													</p>
												</div>

												<div className="mt-4">
													<Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-dark btn-success w-100" type="submit">
                                                        {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign Up
                                                    </Button>
												</div>
												<div className="mt-4 text-center">
													<div className="signin-other-title">
														<h5 className="fs-13 mb-4 title text-muted">or</h5>
													</div>
													<GoogleAuth />
												</div>
											</Form>
										</div>
									</CardBody>
								</Card>
								<div className="mt-4 text-center">
									<p className="mb-0">
										Already have an account ?{" "}
										<Link
											to="/login"
											className="fw-semibold text-white text-primary text-decoration-underline"
										>
											{" "}
											Signin{" "}
										</Link>{" "}
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default Register;
