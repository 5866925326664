import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
} from "reactstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import withRouter from "../../Components/Common/withRouter";
import { APIClient } from "../../helpers/api_helper";

const ResetPasswordPage = (props) => {
    const api = new APIClient();
	
    const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [ uid, setUid ] = useState("");
    const [ token, setToken ] = useState("");

	const location = useLocation();

    const [ resetError, setResetError ] = useState("");
    const [ resetSuccessMsg, setResetSuccessMsg ] = useState("");

    const setPasswordReset = async (values) => {
        setResetError("");
        setResetSuccessMsg("");
        try {
            const response = await api.create(`/api/reset-password/${token}`, values);
            setResetSuccessMsg(response.message);
        } catch (error) {            
            if (error.message) {
                setResetError(`Bad Request: ${error.message}`);
            }
            else {
                setResetError("Error: Reset Password link is expired.");
            }
        }
    };

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
            uid: uid,
			newPassword: "",
			confirmNewPassword: "",
		},
		validationSchema: Yup.object({
			newPassword: Yup.string().required("Please Enter Your New Password"),
			confirmNewPassword: Yup.string()
				.oneOf([Yup.ref("newPassword"), null], "Passwords do not match")
				.required("Please Confirm Your New Password"),
		}),
		onSubmit: (values) => {
			if (values.newPassword === values.confirmNewPassword) {
                setPasswordReset(values);
			} else {
				// Passwords don't match, handle the error
				validation.setErrors({ confirmNewPassword: "Passwords do not match" });
			}
		},
	});

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		setUid(queryParams.get("uid"));
		setToken(queryParams.get("token"));
	}, [location.search]);

	document.title = "Reset Password | DocReader.ai";

	return (
		<ParticlesAuth>
			<div className="auth-page-content">
				<Container>
					<Row>
						<Col lg={12}>
							<div className="text-center mt-sm-5 mb-4 text-white-50">
								<div>
									<Link to="/" className="d-inline-block auth-logo">
										<img src={logoLight} alt="" height="50" />
									</Link>
								</div>
								<p className="mt-3 fs-15 fw-medium">
                                    AI Based Document Response Generator
								</p>
							</div>
						</Col>
					</Row>

					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card className="mt-4">
								<CardBody className="p-4">
									<div className="text-center mt-2">
										<h5 className="text-white">Reset Password</h5>
										<p className="text-muted">
											Please enter your new password below
										</p>

										<lord-icon
											src="https://cdn.lordicon.com/rhvddzym.json"
											trigger="loop"
											colors="primary:#0ab39c"
											className="avatar-xl"
											style={{ width: "120px", height: "120px" }}
										></lord-icon>
									</div>

									<div className="p-2">
										{resetError && resetError ? (
											<Alert color="danger" style={{ marginTop: "13px" }}>
												{resetError}
											</Alert>
										) : null}
										{resetSuccessMsg ? (
											<Alert color="success" style={{ marginTop: "13px" }}>
												{resetSuccessMsg}
											</Alert>
										) : null}
										<Form onSubmit={validation.handleSubmit}>
											<div className="mb-4">
												<Label className="form-label">New Password</Label>
												<div className="password-input">
													<Input
														name="newPassword"
														className="form-control"
														placeholder="Enter new password"
														type={showPassword ? "text" : "password"}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.newPassword}
														invalid={
															validation.touched.newPassword &&
															!!validation.errors.newPassword
														}
													/>
												</div>
												{validation.touched.newPassword &&
													validation.errors.newPassword && (
														<FormFeedback type="invalid">
															<div>{validation.errors.newPassword}</div>
														</FormFeedback>
													)}
												</div>

											<div className="mb-4">
												<Label className="form-label">
													Confirm New Password
												</Label>
												<div className="password-input">
													<Input
														name="confirmNewPassword"
														className="form-control"
														placeholder="Confirm new password"
														type={showConfirmPassword ? "text" : "password"}
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.confirmNewPassword}
														invalid={
															validation.touched.confirmNewPassword &&
															!!validation.errors.confirmNewPassword
														}
													/>
													
												</div>
												{validation.touched.confirmNewPassword &&
													validation.errors.confirmNewPassword && (
														<FormFeedback type="invalid">
															<div>{validation.errors.confirmNewPassword}</div>
														</FormFeedback>
													)}
											</div>

											<div className="text-center mt-4">
												<button className="btn btn-success w-100" type="submit">
													Reset Password
												</button>
											</div>
										</Form>
									</div>
								</CardBody>
							</Card>

							<div className="mt-4 text-center">
								<p className="mb-0">
									Back to Login ? {" "}
									<Link
										to="/login"
										className="fw-semibold text-white text-decoration-underline"
									>
										Click here
									</Link>{" "}
								</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</ParticlesAuth>
	);
};

ResetPasswordPage.propTypes = {
	history: PropTypes.object,
};

export default withRouter(ResetPasswordPage);
