import React, { useState, useEffect } from "react";
import {
	Container,
	Pagination,
	PaginationItem,
	PaginationLink,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { Link } from "react-router-dom";
import FaxDelete from "./FaxDelete";
import moment from "moment";

const Faxes = () => {

	const api = new APIClient();

	const [faxes, setFaxes] = useState([]);
	const [loading, setLoading] = useState(true);

	const [fetchLoading, setFetchLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const fetchNewFaxes = async () => {
		setError(null);
		setSuccess(null);
		setLoading(true);
		setFetchLoading(true);
		try {
			const response = await api.get(`/api/get-faxes/`);
			if (response.status === "error") {
				setError(response.message);
			}
			else {
				setSuccess(response.message);
				setFaxes(response.data);
			}
		} catch (error) {
			setError("Error fetching faxes");
		} finally {
			setLoading(false);
			setFetchLoading(false);
		}
	};

	
	const getFaxes = async () => {
		try {
			const response = await api.get(`/api/fax/`);
			if (response.status === "success") {
				const nonArchivedFaxes = response.data.filter(fax => fax.is_archive === false);
				setFaxes(nonArchivedFaxes);
			} else {
				setError(response.message);
			}
		} catch (error) {
			setError("Error fetching all faxes");
		} finally {
			setLoading(false);
		}
	};
	
	useEffect(() => {
		getFaxes();
	}, []);


	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
						<div className="file-manager-content w-100 p-3 py-0">
							<div className="mx-n3 pt-4 px-4 file-manager-content-scroll">
								<div>
									<div className="d-flex align-items-center mb-3">
										<h5 className="flex-grow-1 fs-16 mb-0" id="filetype-title">
											Faxes
										</h5>
										<div className="flex-shrink-0">
											<button
												className="btn btn-primary mx-2"
												onClick={fetchNewFaxes}
												disabled={fetchLoading}
											>
												{fetchLoading ? 'Fetching...' : '⟳ Fetch'}
											</button>
										</div>
									</div>
									<div
										className="table-responsive"
										style={{ height: "calc(100vh - 350px)" }}
									>
										<table className="table align-middle table-nowrap mb-0">
											<thead className="table-active">
												<tr>
													<th scope="col">S.No.</th>
													<th scope="col">Fax ID</th>
													<th scope="col">Source</th>
													<th scope="col">Created</th>
													{/* <th scope="col">
														Archive
													</th> */}
													<th scope="col">Delete ?</th>
												</tr>
											</thead>
											<tbody id="file-list">
												{faxes && faxes.length > 0 ? (
													faxes.reverse().map((item, index) => (
														<tr key={item.id}>
															<td>
																<Link to={`/fax/${item.fax_id}`}>
																	{index + 1}
																</Link>
															</td>
															<td>
																<Link to={`/fax/${item.fax_id}`}>
																	{item.fax_id}
																</Link>
															</td>
															<td>
																<Link to={`/fax/${item.fax_id}`}>
																	{item.source}
																</Link>
															</td>
															<td>
																{moment(item.created_at).format(
																	"MM-DD-YY - HH:mm"
																)}
															</td>
															{/* <td>
																

																<div className="flex justify-center items-center">
																	<i className="bx bx-archive-in cursor-pointer"></i>
																</div>

															</td> */}
															<td>
																<FaxDelete fax_id={item.fax_id} getFaxes={getFaxes} />
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="6" className="text-center">
															{loading ? (<h4>Loading...</h4>) : (<h4>No faxes found!</h4>)}
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									<div className="align-items-center mt-2 row g-3 text-center text-sm-start">
										<div className="col-sm"></div>
										<div className="col-sm-auto">
											<div className="pagination">
												<Pagination>
													<PaginationItem disabled>
														<PaginationLink previous />
													</PaginationItem>
													<PaginationItem active>
														<PaginationLink>1</PaginationLink>
													</PaginationItem>
													<PaginationItem disabled>
														<PaginationLink next />
													</PaginationItem>
												</Pagination>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Container>
			</div>

			{success && (
				<div className="alert alert-info flying-popup">
					<span>{success}</span>
				</div>
			)}

			{error && (
				<div className="alert alert-danger flying-popup flying-popup-error">
					<span>{error}</span>
				</div>
			)}
		</React.Fragment>
	);
};

export default Faxes;
