
import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Label,
	Input,
	FormFeedback,
	Form,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const Faxes = ({
	profileDataObj,
	getUserProfile,
	updateUserProfile,
	resetFormStatus,
}) => {
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [formSubmitSuccess, setFormSubmitSuccess] = useState(false);
	const [formSubmitError, setFormSubmitError] = useState(null);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			

			app_id: profileDataObj.app_id || "",
			users_id: profileDataObj.users_id || "",
			api_key: profileDataObj.api_key || "",
			destination_fax_number: profileDataObj.destination_fax_number || "",
			fax_prompt: profileDataObj.fax_prompt || "",
			send_fax_mail: profileDataObj.send_fax_mail !== undefined ? profileDataObj.send_fax_mail : false,
			rc_client_id: profileDataObj.rc_client_id || "",
			rc_client_secret: profileDataObj.rc_client_secret || "",
			rc_jwt_token: profileDataObj.rc_jwt_token || "",
			app_id_visible: profileDataObj.is_app_id_visible !== undefined ? profileDataObj.is_app_id_visible : true,
			users_id_visible: profileDataObj.is_users_id_visible !== undefined ? profileDataObj.is_users_id_visible : true,
			api_key_visible: profileDataObj.is_api_key_visible !== undefined ? profileDataObj.is_api_key_visible : true,
			destination_fax_number_visible: profileDataObj.is_destination_fax_number_visible !== undefined ? profileDataObj.is_destination_fax_number_visible : true,
			rc_client_id_visible: profileDataObj.is_rc_client_id_visible !== undefined ? profileDataObj.is_rc_client_id_visible : true,
			rc_client_secret_visible: profileDataObj.is_rc_client_secret_visible !== undefined ? profileDataObj.is_rc_client_secret_visible : true,
			rc_jwt_token_visible: profileDataObj.is_rc_jwt_token_visible !== undefined ? profileDataObj.is_rc_jwt_token_visible : true,
		},
		onSubmit: async (values) => {
			try {
				const response = await updateUserProfile(values);
				
				setFormSubmitSuccess(null); 
				setFormSubmitError(null);
				await updateUserProfile(values);
				setFormSubmitSuccess("Profile saved successfully.");
				formik.setSubmitting(false);
				resetFormStatus();
				console.log("Profile updated successfully:", response);
			} catch (error) {
				console.error("Error updating profile:", error);
				formik.setSubmitting(false);
				setFormSubmitError("Failed to update profile.");
				resetFormStatus();
			}
		},
	});

	useEffect(() => {
		const getFaxesData = async () => {
			try {
				const fetchedProfile = await getUserProfile();
				formik.setValues({
					app_id: fetchedProfile.app_id,
					app_id_visible: fetchedProfile.is_app_id_visible,
					users_id: fetchedProfile.users_id,
					users_id_visible: fetchedProfile.is_users_id_visible,
					api_key: fetchedProfile.api_key,
					api_key_visible: fetchedProfile.is_api_key_visible,
					destination_fax_number: fetchedProfile.destination_fax_number,
					destination_fax_number_visible: fetchedProfile.is_destination_fax_number_visible,
					fax_prompt: fetchedProfile.fax_prompt || "",
					rc_client_id: fetchedProfile.rc_client_id || "",
					rc_client_id_visible: fetchedProfile.is_rc_client_id_visible,
					rc_client_secret: fetchedProfile.rc_client_secret || "",
					rc_client_secret_visible: fetchedProfile.is_rc_client_secret_visible,
					rc_jwt_token: fetchedProfile.rc_jwt_token || "",
					rc_jwt_token_visible: fetchedProfile.is_rc_jwt_token_visible,
				});
				setIsDataLoaded(true);
				resetFormStatus();
			} catch (error) {
				console.error("Error fetching faxes data:", error);
				resetFormStatus();
			}
		};

		if (!isDataLoaded) {
			getFaxesData();
		}
	}, [
		getUserProfile,
		updateUserProfile,
		resetFormStatus,
		formik,
		isDataLoaded,
	]);

	return (
		<Form className="form-horizontal" onSubmit={(e) => {
			e.preventDefault();
			formik.handleSubmit();
		}}>

			<Row>
				{formik.values.app_id_visible &&
					<Col className="pt-3" md="6">
						<div className="form-group">
							<Label className="form-label">App ID</Label>
							<Input
								name="app_id"
								className="form-control"
								placeholder="Enter App ID"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.app_id}
							// readOnly={formik.values.app_id} 
							// value={!formik.is_app_id_visible ? formik.values.app_id || "" : !"Invisible"}
							// invalid={
							// 	formik.touched.app_id && formik.errors.app_id ? true : false
							// }
							/>
							{/* {formik.touched.app_id && formik.errors.app_id ? (
								<FormFeedback type="invalid">{formik.errors.app_id}</FormFeedback>
							) : null} */}
						</div>
					</Col>
				}

				{formik.values.users_id_visible &&
					<Col className="pt-3" md="6">
						<div className="form-group">
							<Label className="form-label">User ID</Label>
							<Input
								name="users_id"
								className="form-control"
								placeholder="Enter User ID"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.users_id}
							// invalid={
							// 	formik.touched.users_id && formik.errors.users_id ? true : false
							// }
							/>
							{/* {formik.touched.users_id && formik.errors.users_id ? (
							<FormFeedback type="invalid">
								{formik.errors.users_id}
							</FormFeedback>
						) : null} */}
						</div>
					</Col>
				}


				{formik.values.api_key_visible &&
					<Col className="pt-3" md="6">
						<div className="form-group">
							<Label className="form-label">API Key</Label>
							<Input
								name="api_key"
								className="form-control"
								placeholder="Enter API Key"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.api_key}
							// invalid={
							// 	formik.touched.api_key && formik.errors.api_key ? true : false
							// }
							/>
							{/* {formik.touched.api_key && formik.errors.api_key ? (
							<FormFeedback type="invalid">
								{formik.errors.api_key}
							</FormFeedback>
						) : null} */}
						</div>
					</Col>
				}

				{formik.values.destination_fax_number_visible &&
					<Col className="pt-3" md="6">
						<div className="form-group">
							<Label className="form-label">Destination Fax Number</Label>
							<Input
								name="destination_fax_number"
								className="form-control"
								placeholder="Enter Destination Fax Number"
								type="text"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.destination_fax_number}
							// invalid={
							// 	formik.touched.destination_fax_number &&
							// 	formik.errors.destination_fax_number
							// 		? true
							// 		: false
							// }
							/>
							{/* {formik.touched.destination_fax_number &&
						formik.errors.destination_fax_number ? (
							<FormFeedback type="invalid">
								{formik.errors.destination_fax_number}
							</FormFeedback>
						) : null} */}
						</div>
					</Col>
				}




				<Col className="pt-3" md="12">
					<div className="form-group">
						<Label className="form-label">Fax Summary Prompt</Label>
						<Input
							name="fax_prompt"
							className="form-control"
							placeholder="Enter prompt for generating summary for Faxes"
							type="textarea"
							rows="5"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.fax_prompt}
						// invalid={
						// 	formik.touched.fax_prompt && formik.errors.fax_prompt
						// 		? true
						// 		: false
						// }
						/>
						{/* {formik.touched.fax_prompt && formik.errors.fax_prompt ? (
							<FormFeedback type="invalid">
								{formik.errors.fax_prompt}
							</FormFeedback>
						) : null} */}
					</div>
				</Col>
			</Row>
			
			{(formik.values.rc_client_id_visible || formik.values.rc_client_secret_visible || formik.values.rc_jwt_token_visible) && (
  <Row style={{ marginTop: '40px' }}>
    <h4>Ring Central Faxes</h4>

    {formik.values.rc_client_id_visible && (
      <Col className="pt-3" md="6">
        <div className="form-group">
          <Label className="form-label">Client ID</Label>
          <Input
            name="rc_client_id"
            className="form-control"
            placeholder="Enter Client ID"
            type="text"
            value={formik.values.rc_client_id}
            onChange={formik.handleChange}
          />
        </div>
      </Col>
    )}

    {formik.values.rc_client_secret_visible && (
      <Col className="pt-3" md="6">
        <div className="form-group">
          <Label className="form-label">Client Secret</Label>
          <Input
            name="rc_client_secret"
            className="form-control"
            placeholder="Enter Client Secret"
            type="text"
            value={formik.values.rc_client_secret}
            onChange={formik.handleChange}
          />
        </div>
      </Col>
    )}

    {formik.values.rc_jwt_token_visible && (
      <Col className="pt-3" md="12">
        <div className="form-group">
          <Label className="form-label">JWT Token</Label>
          <Input
            name="rc_jwt_token"
            className="form-control"
            placeholder="Enter JWT Token"
            type="text"
            value={formik.values.rc_jwt_token}
            onChange={formik.handleChange}
          />
        </div>
      </Col>
    )}
  </Row>
)}

			{/* <div className="text-end mt-4">
				<Button
					type="button"
					color="danger"
					onClick={() => formik.handleSubmit()}
				>
					Save
				</Button>
			</div> */}
<Col className="pt-3" md="6">
					<div className="form-group">
						<Label className="form-label" style={{ paddingRight: "10px" }}>
							Send Fax Summary to e-mail ?
						</Label>
						<Input
							name="send_fax_mail"
							type="checkbox"
							checked={formik.values.send_fax_mail}
							value={formik.values.send_fax_mail}
							onChange={formik.handleChange}
						/>
					</div>
				</Col>
			<div className="text-end mt-4">
				<Button type="submit" color="danger">
					Save
				</Button>
			</div>

			{formSubmitSuccess && (
				<div className="alert alert-success flying-popup">
					<span>{formSubmitSuccess}</span>
				</div>
			)}

			{formSubmitError && (
				<div className="alert alert-danger flying-popup">
					<span>{formSubmitError}</span>
				</div>
			)}
		</Form>
	);
};

export default Faxes;

