import React from "react";
import { Container } from "reactstrap";

const DashboardEcommerce = () => {
  document.title = "DocReader.ai";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          Dashboard
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
