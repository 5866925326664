import React, { useEffect, useState } from "react";
import { APIClient } from "../../helpers/api_helper";
import { Rings } from "react-loader-spinner";
import logoLight from "../../assets/images/logo-light.png";

function PaymentHandler() {
    const api = new APIClient();
    const queryParams = new URLSearchParams(location.search);

    const forceSuccess = queryParams.get('forceSuccess');
    const forceError = queryParams.get('forceError');

    // Get the value of a specific parameter
    const session_id = queryParams.get('session_id');

    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentStatusMessage, setPaymentStatusMessage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [countDown, setCountDown] = useState(5);

    const setRedirectWithLoading = () => {
        const intervalId = setInterval(() => {
            setCountDown((prevCount) => prevCount - 1);
        }, 1000);

        setTimeout(() => {
            clearInterval(intervalId);
            window.location.href = "/profile?showBilling=1";
        }, 5000);
    };

    useEffect(() => {
        if (forceSuccess == "true"){
            setPaymentStatus("success");
            setPaymentStatusMessage("Subscription updated successfully");
            setLoading(false);
            setRedirectWithLoading();
        }

        else if (forceError == "true"){
            setPaymentStatus("error");
            setPaymentStatusMessage("Subscription updated failed");
            setLoading(false);
            setRedirectWithLoading();
        }

        else{
            api
                .get(`/api/payment/feedback?checkout_id=${session_id}`)
                .then((response) => {
                    if (response.status == "success") {
                        setPaymentStatus("success");
                        setPaymentStatusMessage(response.message);
                    } else {
                        setPaymentStatus("failed");
                        setPaymentStatusMessage(response.error);
                    }
                })
                .catch((error) => {
                    console.error("Error checking payment status:", error);
                    setPaymentStatusMessage(error);
                })
                .finally(() => {
                    setLoading(false);
                    setRedirectWithLoading();
                });
            }
    }, [forceSuccess, forceError]);

    if (loading) {
        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Make the container fill the entire viewport height
                    flexDirection: 'column',
                }}>
                    <Rings
                        height="80"
                        width="80"
                        color="#4fa94d"
                        radius="6"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel="rings-loading"
                    />
                    <br />
                    <p>Checking Payment Status...</p>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Make the container fill the entire viewport height
                    flexDirection: 'column',
					fontSize: 100,
					}}><h1>DocReader.ai</h1>{paymentStatus === "success" ? (
						<i className='bx bx-check-circle' style={{ color: "#4fa94d" }}></i>
					) : (
						<i className='bx bx-x-circle' style={{ color: "#d22a2a" }}></i>
					)}
					<h4 className="py-4">{paymentStatusMessage}</h4>
                    
					<a href="/profile?showBilling=1">
                        <h4 className="py-4">You will be automatically redirected in {countDown} seconds...</h4>
						<h6 className="py-4 text-center"><i className='bx bx-arrow-back' ></i> Back to profile</h6>
					</a>
               </div>
            </>
        );
    }
}

export default PaymentHandler;
