import React, { useState, useEffect } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Oval } from "react-loader-spinner";

const PlansInfo = ({ profileDataObj }) => {
	const api = new APIClient();
	const [plans, setPlans] = useState([]);
	const [currentPlan, setCurrentPlan] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingPlanId, setLoadingPlanId] = useState(null);
	const [actionMsg, setActionMsg] = useState("");
	const [errorMsg, setErrorMsg] = useState("");

	useEffect(() => {
		const getPlans = async () => {
			try {
				if (profileDataObj && profileDataObj.plans) {
					setPlans(profileDataObj.plans);
					setCurrentPlan(profileDataObj.subscription.plan);
				}
			} catch (error) {
				console.error("Error fetching plans:", error);
			} finally {
				setLoading(false);
			}
		};
		getPlans();
	}, [profileDataObj]);

	const handleCheckout = async (product_id) => {
		setLoadingPlanId(product_id);
		confirmAlert({
			title: "Update Subscription",
			message: "Are you sure you want to update your subscription ?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						return api
							.create("/api/subscribe/", { product_id: product_id })
							.then((response) => {
								if (response.status === "success") {
									window.location.href = response.checkout_url;
								}
							})
							.catch((error) => {
								setLoadingPlanId(null);
								console.error("Error Upgrading Plan:", error);
								return [];
							});
					},
				},
				{
					label: "No",
					onClick: () => setLoadingPlanId(null),
				},
			],
		});
	};

	const [cancelLoading, setCancelLoading] = useState(false);

	const handleCancel = async (product_id) => {

		setErrorMsg("");
		setActionMsg("");

		setCancelLoading(true);

		confirmAlert({
			title: "Cancel Subscription",
			message: "Are you sure you want to cancel your subscription ?",
			buttons: [
				{
					label: "Yes",
					onClick: () => {
						return api
							.create("/api/cancel-subscription/", { product_id: product_id })
							.then((response) => {
								setCancelLoading(false);
								if (response.status === "success") {
									setActionMsg(response.message);
									setCurrentPlan(response.plan);
								}
								else{
									setErrorMsg(response.message);
								}
							})
							.catch((error) => {
								setCancelLoading(false);
								setErrorMsg(error);
								console.error("Error Cancel Subscription:", error);
								return [];
							});
					},
				},
				{
					label: "No",
					onClick: () => {
						setCancelLoading(false);
						return;
					},
				},
			],
		});
	};

	return (
		<div>
			<div className="py-3">
				<h1>Upgrade Plan</h1>
			</div>
			{loading ? (
				<p>Loading plans...</p>
			) : (
				<Row>
					{plans.map((plan, index) => (
						<Col md={3} key={index}>
							<div className="border p-4" style={{ minHeight: "500px" }}>
								<h3
									className="text-primary-dark fw-medium mb-0"
									style={{ fontSize: "2rem", color: "#939c1f" }}
								>
									{plan.name}
								</h3>
								<h5>{plan.description}</h5>
								<h1
									className="display-2 fw-semibold mb-0 mt-4"
									style={{ fontSize: "30px" }}
								>
									${plan.price}
								</h1>
								<div className="my-4">
									{currentPlan.id === plan.id ? (
										<>
											<a
												style={{
													display: "flex",
													justifyContent: "center",
													fontSize: "15px",
												}}
												className="btn btn-lg w-full lh-sm mt-9 btn-primary disabled"
												disabled={true}
											>
												<i
													className="bx bx-check-circle px-2"
													style={{ paddingTop: "0.1rem" }}
												/>
												Current Plan
											</a>

											{plan.is_default_plan ? null : (
												<a
													style={{
														display: "flex",
														justifyContent: "center",
														fontSize: "15px",
													}}
													className="btn btn-danger btn-lg w-full lh-sm mt-9 btn-primary my-2"
													onClick={() => handleCancel(plan.product_id)}
												>
													{cancelLoading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : <><i
														className="bx bx-block px-2"
														style={{ paddingTop: "0.1rem" }}
													/>Cancel Subscription</> }
												</a>

											)}
										</>
									) : (
										<a
											onClick={() => handleCheckout(plan.product_id)}
											style={{
												display: "flex",
												alignItems: "center",
												fontSize: "15px",
												justifyContent:
													loadingPlanId === plan.product_id
														? "flex-start"
														: "center", // Align left if loadingPlanId matches the plan's product_id
											}}
											className="btn btn-lg w-full lh-sm mt-9 btn-primary"
										>
											{loadingPlanId === plan.product_id && (
												<div style={{ marginRight: "10px" }}>
													<Oval
														height={15}
														width={15}
														color="#4fa94d"
														secondaryColor="#4fa94d"
														strokeWidth={2}
														strokeWidthSecondary={2}
														visible={true}
														ariaLabel="oval-loading"
														wrapperStyle={{}}
													/>
												</div>
											)}
											<div style={{ flex: "1", textAlign: "center" }}>
												Choose Plan
											</div>
										</a>
									)}
								</div>
								<div className="my-4">
									<p>Features: </p>
									<ul
										className="pricing-list d-flex flex-column gap-3 fs-lg mt-9 mb-0"
										style={{ fontSize: "13px" }}
									>
										{plan.features.map((feature, index) => (
											<li key={index}>{feature.name}</li>
										))}
									</ul>
								</div>
							</div>
						</Col>
					))}
				</Row>
			)}
			{actionMsg && (
				<div className="alert alert-info flying-popup">
					<span>{actionMsg}</span>
				</div>
			)}
			{errorMsg && (
				<div className="alert alert-danger flying-popup flying-popup-error">
					<span>{errorMsg}</span>
				</div>
			)}
		</div>
	);
};

export default PlansInfo;
