import React, { useState, useEffect } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Container,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import { Link } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import FaxPageImage from "./FaxPageImage";
import FaxPageText from "./FaxPageText";

const FaxPages = () => {
  const api = new APIClient();

  const { fax_id } = useParams();
  const [activeTab, setActiveTab] = useState("summary");
  const [fax, setFax] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFaxDetails = async () => {
      try {
        const response = await api.get(`/api/fax/${fax_id}/`);
        if (response.status === "success") {
          setFax(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching fax details:", error);
      }
    };
    fetchFaxDetails();
  }, [fax_id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <h5 className="mt-3 fw-medium">
              <a href="/fax">Faxes</a> / #{fax_id}
            </h5>
            <br />
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                active={activeTab === "summary"}
                onClick={() => setActiveTab("summary")}
              >
                Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/"
                active={activeTab === "pages"}
                onClick={() => setActiveTab("pages")}
              >
                Pages
              </NavLink>
            </NavItem>
          </Nav>
          <Card>
            {loading ? (
              <CardBody>
				<h1 style={{textAlign:"center"}}>Loading....</h1>
			  </CardBody>
            ) : (
              <>
                {activeTab === "summary" && (
                  <CardBody>
                    {fax && fax.fax_summary ? (
                      <div style={{ whiteSpace: "pre-wrap" }}>
                        {fax.fax_summary}
                      </div>
                    ) : (
                      <div>No summary generated!</div>
                    )}
                  </CardBody>
                )}
                {activeTab === "pages" && (
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>Page #</th>
                          <th>Page Name</th>

                        </tr>
                      </thead>
                      <tbody>
                        {fax?.pages?.map((page, index) => (
                          <tr key={page.id}>
                            <td>{index + 1}</td>
                            <td>{page.fax_page_name}</td>

                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                )}
              </>
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FaxPages;
